<template>
  <div id="workflow-overview" class="wrapper">
    <Portal to="action-bar">
      <keep-alive>
        <ItemActions
          v-if="showItemActions"
          item="workflow-overview"
          :columns="[]"
          group-by=""
          order=""
          :filter-by="[]"
          criteria=""
          active-view=""
          :kanban-view="isVisibleKanbanView"
          :hide-actions="[
            'toggle',
            'sort',
            'group',
            'filter',
            'activeView',
            'export',
          ]"
          :dashboard-view.sync="activeView"
          :active-kanban-view="activeView"
          @search="searchRow"
          @export="export_Data"
          @refresh="refreshData"
          @clickKanban="showKanbanSettings"
        />
      </keep-alive>
    </Portal>

    <WorkflowFilter
      :workflow-id.sync="workflowId"
      :filter.sync="filter"
      :form-fields="formFields"
      :workflow-list="workflowList"
      :super-user="superUser"
    ></WorkflowFilter>
    <BaseLoadingBarSheet v-if="loadingBarContent" class="loading-content" />
    <template v-if="activeView === 'CHART'">
      <div class="row">
        <div class="col">
          <template v-if="!customChart">
            <OverviewReportCharts
              v-if="workflowList.length"
              :workflow-id="workflowId"
              :sla-settings="slaSettings"
              :form-id="formId"
              :form-fields="formFields"
              :repository-id="repositoryId"
              :repository-fields="repositoryFields"
              :workflow-list="workflowList"
              :workflow="workflow"
              :refresh-inbox.sync="refreshInbox"
              :repository-name="repositoryName"
              @open="openProcess"
              @history="openHistory"
              @attachments="openAttachments"
              @comments="openComments"
            />
          </template>
          <template v-else>
            <BPMSReportChart
              :workflow-id="workflowId"
              :repository-id="repositoryId"
              :repository-fields="repositoryFields"
              :workflow="workflow"
            />
          </template>

          <template v-if="activeView === 'TABLE'"> </template>
        </div>
      </div>
    </template>
    <template v-else-if="activeView === 'SANKEY'">
      <div class="workflowCharts q-mb-sm">
        <div class="row border-bottom">
          <div class="text-bold q-pa-sm col-auto">Data Link Chart</div>
          <template v-if="workflowId">
            <div class="row col">
              <BaseIcon
                v-tooltip.top="'filter'"
                name="eva-funnel-outline"
                color="secondary"
                class="q-mr-sm q-mt-sm"
                size="24px"
              />
              <template v-for="(field, index) in sankeyChartsFilter">
                <div :key="field.id" class="col-auto">
                  <div class="row">
                    <div class="col-auto">
                      <SelectField
                        v-model="sankeyChartsFilter[index]"
                        :options="formFields"
                        :is-clearable="false"
                        class="fieldList q-mb-sm"
                        @input="loadingOverviewChart"
                      >
                      </SelectField>
                    </div>
                    <div class="col-auto">
                      <BaseActionButton
                        v-tooltip.top="'remove filter'"
                        icon="mdi-close"
                        class="plus q-mx-sm"
                        size="14px"
                        is-flat
                        color="red"
                        @click="removeSankeyChartsFilter(index)"
                      />
                    </div>
                  </div>
                </div>
              </template>

              <BaseActionButton
                v-tooltip.top="'add filter'"
                icon="mdi-plus"
                color="secondary"
                class=""
                @click="addSankeyChartsFilter"
              />
            </div>

            <div class="row col-auto">
              <BaseSeparator is-vertical has-inset class="q-mx-sm" />

              <BaseActionButton
                v-for="view in dataViews"
                :key="view.id"
                v-tooltip.top="view.label"
                is-flat
                no-border
                :icon="dataView === view.value ? view.activeIcon : view.icon"
                :color="dataView === view.value ? 'secondary' : 'gray'"
                @click="dataView = view.value"
              />
            </div>
            <div class="col-auto">
              <BaseActionButton
                icon="mdi-pin"
                :color="pined ? 'secondary' : 'gray'"
                no-border
                is-flat
                size="20px"
                rotate="rotate-45"
                @click="pined ? deleteItem('sankey') : saveFilter('sankey')"
              />
            </div>
          </template>
        </div>
        <div v-if="dataView === 'SANKEY'" class="row chart">
          <vue-highcharts
            ref="refCharts"
            :highcharts="Highcharts"
            :options="sankeyCharts"
            style="width: 100%"
          ></vue-highcharts>
        </div>
        <div v-else-if="dataView === 'TABLE'">
          <div class="workflowReports">
            <BaseScrollbar height="calc(100vh - 300px)">
              <table>
                <thead>
                  <tr>
                    <!-- columns -->

                    <th v-for="column in columns" :key="column.id">
                      {{ column.name }}
                    </th>

                    <!-- ... -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in tableReport" :key="item.id">
                    <!-- columns -->

                    <td v-for="column in columns" :key="column.id">
                      <slot
                        :name="column.name"
                        :value="item[column.name]"
                        :item="item"
                      >
                        {{ item[column.name] }}
                      </slot>
                    </td>

                    <!-- ... -->
                  </tr>
                </tbody>
              </table>
            </BaseScrollbar>
          </div>
        </div>
      </div>
    </template>

    <template v-if="showKanbanView && activeView === 'KANBAN'">
      <WorkflowKanban
        :kanban-list="kanbanSettings"
        :kanban-data-list="kanbanSettingsList"
        :workflow-name="workflowName"
        :workflow-id="workflowId"
        :workflow-request-list="workflowProcessList"
        :workflow="workflow"
        :form="form"
        :form-id="formId"
        :enable-controls="_enableControls"
        @showProcessSheet="processSheet = true"
        @searchKanban="searchKanban"
      />
    </template>

    <Sheet
      v-model="processSheet"
      width="100vw"
      has-footer
      no-padding
      @input="processSheet = false"
    >
      <!-- title -->

      <template #title> {{ headerTitle }} </template>

      <!-- ... -->

      <!-- actions -->

      <template v-if="actions.length" #actions>
        <BaseButton
          v-if="hasWflowInitiator"
          :label="subWorkflowName"
          class="q-mr-sm"
          color="secondary"
          @click="initiateSubWorkflow"
        />
        <template v-if="formId">
          <BaseActionButton
            v-if="!formView"
            v-tooltip.top="'Form View'"
            is-flat
            :icon="'mdi-form-select'"
            class="q q-mr-sm"
            @click="formView = true"
          />

          <BaseActionButton
            v-else
            v-tooltip.top="'Details View'"
            is-flat
            :icon="'mdi-table-column'"
            class="q-mr-sm"
            @click="formView = false"
          />
        </template>
      </template>

      <!-- content -->

      <div id="content-wrapper" class="row">
        <div v-if="formId" class="col">
          <!-- <div class="header">
              <div class="col">
                <div class="title-1">{{ formName }}</div>
                <div class="description">{{ formDescription }}</div>
              </div>
            </div> -->
          <!-- form -->
          <BaseScrollbar height="calc(100vh - 115px)">
            <div v-if="formView" id="form-view">
              <RenderForm
                v-if="formId"
                :form-id="formId"
                :panels="panels"
                :secondary-panels="secondaryPanels"
                :form-settings="formSettings"
                :form-model="formModel"
                :save-action.sync="saveAction"
                :workflow-form-model.sync="workflowFormModel"
                :is-readonly="formEditAccess"
                :form-edit-controls="formEditControls"
                :readonly-controls="readonlyControls"
                :form-visibility-access="formVisibilityAccess"
                :form-secure-controls="formSecureControls"
                :enable-controls="_enableControls"
                :check-list.sync="checkList"
              />

              <div
                v-if="signList.length"
                id="approvalSignature"
                class="q-pa-md"
              >
                <div class="title q-mb-sm">Approval Signature</div>

                <BaseSeparator class="q-my-sm" />

                <div class="row">
                  <template v-for="sign in signList">
                    <div :key="sign.id" class="col-3 q-mb-sm">
                      <img class="imgCenter col" :src="signLink(sign.id)" />
                      <div class="text row">
                        <span class="col-12 text-bold">{{ sign.stage }}</span>
                        <template v-if="sign.review">
                          <span
                            v-if="sign.review.toLowerCase() === 'approve'"
                            class="col-12 text-bold text-positive"
                            >Approved
                          </span>
                          <span
                            v-else-if="sign.review.toLowerCase() === 'reject'"
                            class="col-12 text-bold text-red"
                            >Rejected
                          </span>
                          <span
                            v-else
                            class="col-12 text-bold text-secondary"
                            >{{ sign.review }}</span
                          >
                        </template>
                        <span class="col-12">{{ sign.createdByEmail }}</span>
                        <span class="col-12">{{
                          $day.format(sign.createdAt)
                        }}</span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <SignatureField
                v-if="userSignature"
                v-model="signImg"
                label="Signature"
                class="q-pa-md"
              />
            </div>
            <div v-else class="q-pa-md">
              <!-- <div class="col-12 q-mb-sm q-pt-md stepper">
                <Stepper
                  :stage-level="stageLevel"
                  :current-step="currentStep"
                />
              </div> -->
              <div id="requestDetails" class="col-12">
                <div class="row justify-center">
                  <div class="col">
                    <div class="row items-center">
                      <table class="submittedData q-mb-sm">
                        <tbody>
                          <tr v-if="selectedProcess.createdByName">
                            <td colspan="4">
                              Requested By:
                              {{ selectedProcess.createdByName }}
                            </td>
                          </tr>
                          <tr>
                            <th v-if="selectedProcess.raisedBy">
                              Requested User Email
                            </th>
                            <th>Requested Date</th>
                            <th>Last Acted By</th>
                            <th>Last Acted On</th>
                          </tr>
                          <tr>
                            <td v-if="selectedProcess.raisedBy">
                              {{ selectedProcess.raisedBy }}
                            </td>
                            <td>
                              {{ $day.format(selectedProcess.raisedAt) }}
                            </td>
                            <td>
                              {{ selectedProcess.transaction_createdByEmail }}
                            </td>
                            <td>
                              {{
                                $day.format(
                                  selectedProcess.transaction_createdAt
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <BaseScrollbar height="calc(100vh - 275px)">
                        <template>
                          <table class="mainData col-12">
                            <template
                              v-for="(value, key) in selectedProcess.formData
                                .fields"
                            >
                              <template v-if="value && value != '[]'">
                                <template v-if="validateType(key) !== 'TABLE'">
                                  <tr :key="`${key}-${value}`">
                                    <td class="tblHeader ellipsis small">
                                      {{ getLabel(key) }}
                                    </td>
                                    <td class="ellipsis">
                                      <template
                                        v-if="
                                          validateType(key) === 'FILE_UPLOAD'
                                        "
                                      >
                                        <div v-if="JSON.parse(value).length">
                                          <div
                                            v-for="file in JSON.parse(value)"
                                            :key="file.fileName"
                                            class="q-mb-xs"
                                          >
                                            <div class="row items-center">
                                              <div
                                                class="col row items-center"
                                                @click="viewFile(file, value)"
                                              >
                                                <FileIcon
                                                  :mime-type="
                                                    fileType(file.fileName)
                                                  "
                                                  class="mini-avatar q-mr-sm"
                                                />
                                                <span
                                                  class="cursor-pointer text-underline text-secondary"
                                                  >{{ file.fileName }}</span
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </template>
                                      <template
                                        v-else-if="validateType(key) === 'DATE'"
                                      >
                                        {{ $day.format(value, false) }}
                                      </template>
                                      <template
                                        v-else-if="validateValue(value)"
                                      >
                                        {{ removeTags(value) || "-" }}
                                      </template>
                                      <template v-else>
                                        <a :href="value" target="_blank">
                                          {{ value || "-" }}
                                        </a>
                                      </template>
                                    </td>
                                  </tr>
                                </template>
                                <template v-else-if="JSON.parse(value).length">
                                  <tr
                                    v-if="getLabel(key)"
                                    :key="'trlabel-' + key"
                                  >
                                    <td
                                      colspan="2"
                                      class="tblHeader ellipsis small"
                                    >
                                      <FormFieldLabel
                                        :key="'label-' + key"
                                        :label="getLabel(key)"
                                        class="q-mt-sm"
                                      />
                                    </td>
                                  </tr>
                                  <tr :key="'trtable1-' + key">
                                    <td colspan="2">
                                      <BaseScrollbar
                                        :key="'scroll-' + key"
                                        style="max-height: 200px"
                                      >
                                        <table
                                          :key="'table-' + key"
                                          class="sub-table q-mt-sm q-mb-sm"
                                        >
                                          <thead>
                                            <tr>
                                              <th
                                                v-for="(
                                                  field_item, field_key
                                                ) in tableValue(value)[0]"
                                                :key="field_key"
                                              >
                                                {{
                                                  getLabel(field_key, "table")
                                                }}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              v-for="(
                                                field_item, field_key
                                              ) in tableValue(value)"
                                              :key="field_key"
                                            >
                                              <td
                                                v-for="(
                                                  row_value, row_key
                                                ) in field_item"
                                                :key="row_key"
                                              >
                                                {{ row_value }}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </BaseScrollbar>
                                    </td>
                                  </tr>
                                </template>
                              </template>
                            </template>
                          </table>
                        </template>
                      </BaseScrollbar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BaseScrollbar>
        </div>

        <div v-if="itemModel.itemId" class="col">
          <div class="fileFrame">
            <div class="file-list">
              <iframe
                id="file-frame"
                ref="myFiles"
                :src="itemLink()"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>

        <div class="col-auto q-mr-sm leftBorder">
          <template v-for="tab in tabs">
            <BaseActionButton
              v-if="checkTabAccess(tab.value)"
              :key="tab.id"
              v-tooltip.left="tab.label"
              is-flat
              no-border
              :icon="tab.icon"
              color="primary"
              class="tabButtons"
              @click="showLinks(tab.value)"
            >
              <span v-if="tab.count" class="badge">{{ tab.count }} </span>
            </BaseActionButton>
          </template>
        </div>
        <!-- ... -->
      </div>

      <!-- ... -->

      <!-- footer -->
      <template #footer>
        <div v-if="actions.length" class="footer row">
          <BaseButton
            label="Save"
            class="q-ml-md"
            color="secondary"
            @click="validateRequest('Save')"
          />
          <template v-for="action in actions">
            <BaseButton
              v-if="
                action === 'Approve' ||
                action === 'Complete' ||
                action === 'Rightsize'
              "
              :key="action"
              :label="action"
              color="positive"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
            <BaseButton
              v-else-if="
                action === 'Reject' ||
                action === 'Terminate' ||
                action === 'Force Close'
              "
              :key="action"
              :label="action"
              color="red"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
            <BaseButton
              v-else-if="action === 'Forward' || action === 'Reply'"
              :key="action"
              :label="action"
              color="orange"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
            <BaseButton
              v-else
              :key="action"
              :label="action"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
          </template>
        </div>
      </template>
      <!-- ... -->
    </Sheet>

    <Sheet
      v-model="attachmentSheet"
      width="30vw"
      :sub-actions="selectedProcess.processId ? true : false"
      :upload="true"
      @input="closeAttachmentsSheet"
    >
      <!-- title -->

      <template #title> Attachments </template>

      <template #actions>
        <div
          v-if="selectedProcess.processId"
          class="q-ml-sm text-gray q-mr-sm ellipsis"
          style="font-size: 10px"
        >
          auto-refresh every 30 seconds
        </div>
        <BaseActionButton
          v-if="selectedProcess.processId"
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getAttachments()"
        />
      </template>

      <template #upload>
        <div
          class="hint"
          @click="
            {
              rowIndex = null;
              $refs.attachments.click();
            }
          "
        >
          <BaseIcon
            name="eva-cloud-upload-outline"
            size="32px"
            color="blue"
            class="icon"
          />

          <div class="title text-blue">UPLOAD FILES</div>
          <div class="description">Click here to choose files and upload</div>
        </div>
      </template>

      <template
        v-if="selectedProcess.processId && attachmentList.length"
        #subActions
      >
        <CheckboxField
          v-model="selectAll"
          class="col-auto q-pt-sm q-pb-sm"
          @input="fileSelectAll"
        />
        Select All

        <BaseActionButton
          v-tooltip.top="'mail share'"
          :is-disabled="!selectedFileCount"
          icon="mdi-email-send-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          class="q-ml-sm"
          @click="documentShare"
        />

        <BaseActionButton
          v-tooltip.top="'merge documents'"
          :is-disabled="selectedFileCount > 1 && isSelectedPDF ? false : true"
          icon="mdi-file-document-multiple-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          @click="documentMerge"
        />
      </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar height="calc(100vh - 250px)">
          <input
            ref="attachments"
            type="file"
            class="hidden"
            @change="uploadAttachments"
          />
          <template v-for="row in checkList">
            <template v-if="row.name">
              <div v-if="!row.attach" :key="row.uid" class="checkList">
                <div class="row">
                  <div class="col-auto">
                    <BaseIcon name="mdi-file-check-outline" color="primary" />
                  </div>
                  <div class="col q-ml-sm">
                    <div class="row">
                      <div class="col-12">
                        {{ row.name }}
                      </div>
                      <div v-if="row.required" class="col-12 q-mt-sm">
                        <q-chip outline color="red" class="chips small">
                          Required
                        </q-chip>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="col-12" @click="attachTemplate(row.uid)">
                      <q-chip
                        outline
                        color="primary"
                        class="chips primary-hover medium"
                      >
                        <q-avatar
                          icon="eva-upload-outline"
                          class="q-mr-sm"
                          color="primary"
                          text-color="white"
                        />
                        Upload
                      </q-chip>
                    </div>
                    <div
                      v-if="row.template"
                      class="col-12"
                      @click="download(row.id)"
                    >
                      <q-chip
                        outline
                        color="secondary"
                        class="chips secondary-hover medium"
                      >
                        <q-avatar
                          icon="eva-download-outline"
                          class="q-mr-sm"
                          color="secondary"
                          text-color="white"
                        />
                        Download
                      </q-chip>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <template v-for="file in attachmentList">
            <div :key="file.id" class="attachmentList">
              <div class="title row">
                <div v-if="selectedProcess.processId" class="col-auto">
                  <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                  <BaseIcon
                    :name="
                      file.checked
                        ? 'eva-checkmark-circle-2'
                        : 'eva-radio-button-off-outline'
                    "
                    color="secondary"
                    class="q-mr-sm"
                    @click="file.checked = !file.checked"
                  />
                </div>
                <div v-if="file.formJsonId" class="col-auto">
                  <BaseIcon
                    v-tooltip:green.top="getLabel(file.formJsonId)"
                    name="mdi-form-select"
                    color="primary"
                    class="q-mr-sm"
                  />
                </div>
                <div class="col-auto">
                  <FileIcon
                    :mime-type="fileType(file.name)"
                    class="mini-avatar q-mr-sm"
                  />
                </div>
                <div class="col ellipsis" @click="openFile(file)">
                  {{ file.name }}
                </div>
                <div class="col-auto">
                  <BaseActionButton
                    v-tooltip.top="'Metadata'"
                    icon="eva-file-text-outline"
                    color="secondary"
                    no-border
                    is-flat
                    size="20px"
                    @click.stop="getFileData(file)"
                  />
                </div>
                <div class="col-auto">
                  <BaseActionButton
                    v-tooltip.top="'delete'"
                    icon="eva-trash-2-outline"
                    color="red"
                    no-border
                    is-flat
                    size="20px"
                    @click.stop="attachmentDelete(file.id)"
                  />
                </div>
              </div>

              <div class="meta">
                <!-- created at -->

                <div>{{ $day.format(file.createdAt) }}</div>

                <!-- ... -->

                <!-- created by -->

                <template v-if="file.createdBy">
                  <BaseIcon name="mdi-circle-small" />
                  <div>{{ file.createdByEmail }}</div>
                </template>

                <!-- ... -->

                <!-- size -->

                <template v-if="file.size">
                  <BaseIcon name="mdi-circle-small" />
                  <div>{{ fileSize(file.size) }}</div>
                </template>

                <!-- ... -->
              </div>
            </div>
          </template>
        </BaseScrollbar>
      </div>
    </Sheet>

    <Sheet
      v-model="commentsSheet"
      width="30vw"
      height="80vh"
      custom-height="30"
      @input="closeCommentsSheet"
    >
      <!-- title -->

      <template #title> Comments </template>

      <!-- ... -->

      <template #actions>
        <div
          v-if="selectedProcess.processId"
          class="q-ml-sm text-gray q-mr-sm ellipsis"
          style="font-size: 10px"
        >
          auto-refresh every 30 seconds
        </div>

        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getComments"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar :height="commentsSheetHeight">
          <!-- comments -->
          <template v-for="(row, idx) in commentsList">
            <q-chat-message
              :key="idx"
              :name="
                row.createdBy === $store.state.session.id
                  ? 'me'
                  : row.createdByEmail || row.externalCommentsby
              "
              :text="[row.comments]"
              text-html
              :sent="row.createdBy === $store.state.session.id"
              :stamp="lastActionDuration(row)"
              :bg-color="
                row.createdBy === $store.state.session.id
                  ? 'primary-1'
                  : 'secondary-1'
              "
              class="q-ma-sm"
            >
              <template v-slot:avatar>
                <div
                  v-if="row.embedFileList && row.embedFileList.length"
                  class="file-icons"
                >
                  <FileIcon
                    :key="`Icon-${idx}`"
                    v-tooltip.top="row.embedFileList[0].fileName"
                    :mime-type="fileType(row.embedFileList[0].fileName)"
                    class="file-icon q-mr-sm"
                  />
                </div>
              </template>
            </q-chat-message>
          </template>
          <!-- ... -->
        </BaseScrollbar>
      </div>

      <!-- ... -->

      <div class="col">
        <div class="row">
          <template v-if="checkTenant()">
            <div class="col actions">
              <div
                v-if="attachmentList.length && fileNames.length"
                class="row select-action q-pa-sm action-container"
              >
                <CheckboxField
                  v-model="attachComment"
                  label="Add Comment to PDF"
                  class="q-pr-sm"
                  :class="attachComment ? 'col-auto' : 'col'"
                />

                <CheckboxField
                  v-if="selectedProcess.stageType !== 'START' && !attachComment"
                  v-model="notifyInitiator"
                  label="Notify Initiator/Requester"
                  class="col-auto notify-cmd"
                />

                <SelectField
                  v-if="attachComment"
                  v-model="selectedFileForAttach"
                  :is-searchable="true"
                  :options="fileNames"
                  class="col field comment-attach attach-cmd"
                />
              </div>

              <CheckboxField
                v-if="
                  selectedProcess.stageType !== 'START' &&
                  (attachComment ||
                    !(attachmentList.length && fileNames.length))
                "
                v-model="notifyInitiator"
                label="Notify Initiator/Requester"
                :class="notifyInitiatorClass"
                class="col-12 q-pl-sm q-pb-sm q-pt-sm"
              />

              <SingleChoiceField
                v-if="displayInternalPublic"
                v-model="commentAction"
                :options="commentActions"
                :show-options-wrapper="false"
                :options-per-line="2"
                class="action-container"
                :class="{
                  'internal-public': attachmentList.length > 0,
                  'col q-pa-sm': true,
                  'internal-public-margin': attachComment,
                }"
              />
            </div>
          </template>
        </div>
        <div class="row text-area-action q-ml-sm q-mr-sm">
          <div class="col">
            <TextAreaField
              v-model="commentText"
              auto-focus
              placeholder="Type a new comment..."
            />
          </div>
          <div class="col-auto" style="margin: auto; margin-right: 8px">
            <BaseActionButton
              v-tooltip.left="'Post Comments'"
              icon="eva-paper-plane-outline"
              color="primary-11"
              class="q-ml-sm comment-actions"
              size="16px"
              rotate-class="rotate-45"
              @click="saveComment"
            />
            <BaseActionButton
              v-tooltip.left="'Advanced Comments'"
              icon="mdi-comment-text-multiple"
              color="primary-11"
              class="q-ml-sm q-mt-sm comment-actions"
              size="16px"
              @click="advancedCommentsSheet = true"
            />
          </div>
        </div>
      </div>
    </Sheet>

    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <template v-if="selectedFile.formJsonId">
            <BaseActionButton
              v-tooltip:green.bottom="getLabel(selectedFile.formJsonId)"
              is-flat
              icon="mdi-form-select"
              color="primary"
              class="q-mr-sm"
            />
          </template>
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.name)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.name }}
            </div>

            <div class="meta">
              <div>Archived At: {{ $day.format(selectedFile.createdAt) }}</div>
              <BaseIcon name="mdi-circle-small" />
              <div>Archived By: {{ selectedFile.createdByEmail }}</div>
              <div v-if="selectedFile.size">
                <BaseIcon name="mdi-circle-small" />
                File Size: {{ fileSize(selectedFile.size) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="documentAction(action)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
      </template>

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myFiles"
            :src="fileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>

    <Sheet v-model="taskSheet" width="30vw" @input="closeTaskSheet">
      <!-- title -->

      <template #title> Tasks </template>

      <!-- ... -->

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          @click="getTaskList"
        />

        <BaseActionButton
          v-tooltip.top="'Add Task'"
          icon="mdi-plus"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="addTask"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <template v-for="task in taskEntries">
          <div :key="task.id" class="taskEntries">
            <div class="col-12">
              <div class="row">
                <div
                  class="col-auto q-mr-md"
                  style="margin-top: 3px"
                  @click="viewTask(task.id)"
                >
                  {{ task.name }}
                </div>
                <q-chip
                  size="10px"
                  :color="getColor(task.priority)"
                  text-color="white"
                >
                  {{ task.priority }}
                </q-chip>
                <q-chip
                  size="10px"
                  :color="getColor(task.status)"
                  text-color="white"
                >
                  {{ task.status }}
                </q-chip>
              </div>
            </div>

            <div class="meta row">
              <div class="col-12">Assigned To: {{ task.assigned }}</div>
              <div class="col-12">
                Start Date: {{ $day.format(task.startDate, false) }}
                <BaseIcon name="mdi-circle-small" />
                End Date: {{ $day.format(task.endDate, false) }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </Sheet>

    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="advancedCommentsSheet = false"
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <template v-if="checkTenant()">
          <div class="row actions advance-cmd">
            <template v-if="attachmentList.length && fileNames.length">
              <CheckboxField
                v-model="attachComment"
                label="Add Comment to PDF"
                class="col-auto q-pr-sm"
                style="margin: auto"
              />

              <SelectField
                v-if="attachComment"
                v-model="selectedFileForAttach"
                :is-searchable="true"
                :options="fileNames"
                class="field comment-attach"
              />
              <CheckboxField
                v-if="selectedProcess.stageType !== 'START'"
                v-model="notifyInitiator"
                label="Notify Initiator/Requester"
                class="col-auto q-pl-sm adv-notify-cmd"
              />
            </template>
          </div>
          <SingleChoiceField
            v-if="checkTenant() && displayInternalPublic"
            v-model="commentAction"
            :options="commentActions"
            :options-per-line="2"
            class="actions q-mr-sm noborder"
          />
        </template>
        <BaseButton
          label="Post Comments"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>

    <Confirm
      v-model="errorModal"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="
        {
          errorModal = false;
          errorMessage = '';
        }
      "
    >
      <template #title>Error Message</template>

      <template #default>
        <div class="q-py-md">
          <p>
            {{ errorMessage }}
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Close"
          color="red"
          @click="
            {
              errorModal = false;
              errorMessage = '';
            }
          "
        />
      </template>
    </Confirm>

    <Confirm
      v-model="actionPasswordConfirm"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="actionPasswordConfirm = false"
    >
      <template #title> Action Password</template>

      <template #default>
        <ValidationObserver ref="passwordForm">
          <!-- password -->

          <ValidationProvider
            v-slot="{ errors }"
            name="password"
            :rules="{ required: true, password: true }"
          >
            <PasswordField
              v-model="actionUserPassword"
              is-mandatory
              :is-readonly="isLoading"
              :error="errors[0]"
              :auto-focus="true"
              class="q-my-lg"
              @enter="validatePassword"
            />
          </ValidationProvider>

          <!-- ... -->
        </ValidationObserver>
      </template>

      <template #footer>
        <BaseButton
          label="validate"
          :is-loading="isLoading"
          @click="validatePassword"
        />
      </template>
    </Confirm>

    <Confirm
      v-model="actionConfirmAlert"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="
        {
          saveAction = '';
          workflowFormModel = {};
          actionConfirmAlert = false;
        }
      "
    >
      <template #title> Confirmation</template>

      <template #default>
        <div class="q-py-md">
          <p>
            Are you sure you want to confirm the action as "<b>{{
              saveAction
            }}</b
            >"?
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          color="primary"
          @click="
            {
              saveAction = '';
              workflowFormModel = {};
              actionConfirmAlert = false;
            }
          "
        />
        <BaseButton
          label="yes"
          color="primary"
          @click="
            {
              actionConfirmAlert = false;
              processRequest(false, false);
            }
          "
        />
      </template>
    </Confirm>

    <Modal
      v-model="processForwardModal"
      width="40vw"
      height="60vh"
      has-footer
      @input="
        {
          saveAction = '';
          workflowFormModel = {};
          processForwardModal = false;
        }
      "
    >
      <!-- title -->

      <template #title
        ><span v-if="!processForwardGroupAction"
          >"{{ selectedProcess.requestNo }}"</span
        >
        Process Forward</template
      >

      <!-- ... -->

      <template #default>
        <div id="processForward">
          <!-- user -->

          <MultiSelectField
            v-if="internalForward.proceed"
            v-model="forwardUsers"
            label="users"
            is-mandatory
            :options="userList"
            class="field"
          />

          <!-- ... -->

          <!-- group -->

          <MultiSelectField
            v-if="internalForward.groups.length"
            v-model="forwardGroups"
            label="group"
            :options="groupList"
            class="field"
          />

          <!-- ... -->

          <TextAreaField
            v-if="processForwardGroupAction"
            v-model="commentText"
            placeholder="Type a comment..."
            class="field q-mt-md"
          />
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Forward"
          color="orange"
          @click="
            {
              processForwardModal = false;
              processForwardGroupAction
                ? groupProcessRequest()
                : processRequest(false, false);
            }
          "
        />
      </template>
    </Modal>

    <Confirm
      v-model="alertModal"
      icon="mdi-file-alert"
      icon-color="red"
      @input="closeAlertModal"
    >
      <template #title>Documents Required!</template>

      <template #default>
        <div class="q-py-md text-md">
          <p>
            Are you sure you want to submit the request without attaching the
            required documents.
          </p>

          <!-- <p class="text-secondary">
            After submitting, you can upload the required documents from sent
            list once you have them.
          </p> -->
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          @click="closeAlertModal"
        />
        <BaseButton label="yes" color="primary" @click="proceedSubmission" />
      </template>
    </Confirm>

    <AddTask
      v-if="taskFormId"
      :value="addTaskSheet"
      :form-settings="addTaskForm.settings"
      :panels="addTaskForm.panels"
      :secondary-panels="addTaskForm.secondaryPanels"
      :form-id="taskFormId"
      :repository-id="taskRepositoryId"
      :task-model="selectedTask"
      :workflow-task="true"
      :mode="taskMode"
      @close="addTaskSheet = false"
      @addEntry="addTaskEntry"
    ></AddTask>

    <FileDetails v-model="fileDetailsSheet" :file-data="fileData"></FileDetails>

    <EmailSharing
      v-model="emailSharingSheet"
      :repository-id="workflow.repositoryId"
      :file-list.sync="selectedFileList"
    ></EmailSharing>

    <ProcessHistory
      v-if="historyProcessId"
      :process-id="historyProcessId"
      :selected-process="selectedProcess"
      @close="historyProcessId = 0"
    />

    <SubWorkflowProcess
      :workflow-id="subWorkflowId || subProcessData.id"
      :initiate-sub-workflow-process="initiateSubWorkflowProcess"
      :main-workflow-id="workflowId"
      :main-process-id="selectedProcess.processId"
      :sub-process-link="subProcessLink"
      :selected-process="subProcessData"
      :open-sub-process="openSubProcessSheet"
      :main-form-fields="formFields"
      :main-form-model="formModel"
      :sub-process-id.sync="subProcessId"
      @closeProcessSheet="closeSubProcess"
    />

    <Sheet v-model="subProcessSheet" width="30vw" @input="closeSubProcessSheet">
      <!-- title -->

      <template #title> Linked Request </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <template v-for="(process, index) in subProcessTickets">
          <div :key="index" class="subProcess">
            <div class="title row">
              <div class="col-auto">
                <BaseIcon
                  name="o_account_tree"
                  class="q-mr-sm"
                  color="primary-4"
                />
              </div>
              <div
                class="col ellipsis text-underline"
                @click="openSubProcess(process)"
              >
                {{ process.requestNo }}
              </div>
              <q-chip
                v-if="process.flowStatus === '1'"
                size="10px"
                color="green"
                text-color="white"
              >
                Completed
              </q-chip>
              <q-chip v-else size="10px" color="orange" text-color="white">
                Running
              </q-chip>
            </div>

            <div class="meta row">
              <div class="col row">
                <div>{{ process.name }}</div>
                <BaseIcon name="mdi-circle-small" />
                <div>{{ process.stage }}</div>
                <BaseIcon name="mdi-circle-small" />
                <div>{{ process.duration }} ago</div>
              </div>
            </div>
            <div v-if="false" class="col-auto q-mr-sm q-pt-md leftBorder">
              <template v-for="tab in subTabs">
                <BaseActionButton
                  :key="tab.id"
                  v-tooltip.left="tab.label"
                  is-flat
                  no-border
                  :icon="tab.icon"
                  color="primary"
                  class="linkActions"
                  size="16px"
                  @click="subProcessShowLinks(tab.value, process)"
                >
                  <span
                    v-if="
                      tab.value === 'ATTACHMENTS' && process.attachmentCount
                    "
                    class="badge"
                    >{{ process.attachmentCount }}
                  </span>

                  <span
                    v-if="tab.value === 'COMMENTS' && process.commentsCount"
                    class="badge"
                    >{{ process.commentsCount }}
                  </span>
                </BaseActionButton>
              </template>
            </div>
          </div>
        </template>
      </div>
    </Sheet>

    <FormEntryHistory
      v-if="showFormEntryHistoryModal"
      :value.sync="showFormEntryHistoryModal"
      :workflow-id="workflow.id"
      :process-id="selectedProcess.processId"
    />

    <Attachments
      v-if="showAttachmentSheet"
      :value.sync="showAttachmentSheet"
      :selected-process="selectedProcess"
      :workflow="workflow"
      :selected-node="'inbox'"
      :initiate-process="false"
      :form-check-list="false"
      :check-list="checkList"
      :wform="wform"
      :repository-details="repositoryDetails"
      :workflow-model="workflowFormModel"
      :form-upload-files="[]"
      :repository-field="repositoryFields"
      :columns="columns"
      :attachments.sync="attachmentList"
      :workflow-id="workflow.value"
      @attachmentCount="attachmentCount"
    />

    <Comments
      v-if="showCommentsSheet"
      :value.sync="showCommentsSheet"
      :selected-process="selectedProcess"
      :workflow="workflow"
      :selected-node="'inbox'"
      :initiate-process="false"
      :attachment-list="attachmentList"
      :workflow-id="workflow.value"
      :file-names="fileNames"
      :comments.sync="commentsList"
      :display-internal-public="displayInternalPublic"
      @commentsCount="commentsCount"
    />

    <UnpinDashboard
      v-model="isDeleteConfirmationVisible"
      :delete-data="deleteData"
      :label="deleteName"
      @delete="
        {
          pined = false;
          getFilterList();
        }
      "
    />
  </div>
</template>

<script>
import { Portal } from "portal-vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import {
  workflow,
  form,
  repository,
  auth,
  menu,
  report,
  user,
  group,
  transaction,
  dashboard,
} from "@/api/factory.js";
import WorkflowFilter from "./workflows-overview/WorkflowsFilter.vue";
import OverviewReportCharts from "./workflows-overview/OverviewReportCharts.vue";
import BPMSReportChart from "./workflows-overview/BPMSReportChart.vue";
import { exportData } from "@/helpers/export-excel-data";
import Sheet from "@/components/common/popup/Sheet.vue";
import RenderForm from "@/components/common/render-form/RenderForm.vue";
import SignatureField from "@/components/common/form/signature-field/SignatureField.vue";
import { pdfSupport, htmlSupport, fileSupport } from "@/helpers/file-format.js";
import formatBytes from "@/helpers/format-bytes.js";
import Modal from "@/components/common/popup/Modal.vue";
import axios from "axios";
import Confirm from "@/components/common/popup/Confirm.vue";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import FileIcon from "@/components/common/FileIcon.vue";
import ListItem from "@/components/common/ListItem.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import { Chart, LinearScale } from "chart.js";
import { SankeyController, Flow } from "chartjs-chart-sankey";
import VueHighcharts from "vue2-highcharts";
import Highcharts from "highcharts";
import Exporting from "../../../../node_modules/highcharts/modules/sankey.js";
import WorkflowKanban from "./workflows-overview/WorkflowKanban.vue";
import AddTask from "@/views/tasks/views/tasks-browse-and-trash/components/AddTask";
import FileDetails from "./workflows-inbox/components/FileDetails.vue";
import EmailSharing from "@/components/common/EmailSharing.vue";
import ProcessHistory from "@/components/common/workflow/ProcessHistory.vue";
// import Stepper from "@/components/common/workflow/Stepper.vue";
import SubWorkflowProcess from "./workflows-inbox/components/components/SubWorkflow.vue";
import FormEntryHistory from "@/components/common/workflow/FormEntryHistory.vue";
import Attachments from "@/components/common/workflow/Attachments.vue";
import Comments from "@/components/common/workflow/Comments.vue";
import UnpinDashboard from "@/components/common/display/item-actions/components/UnpinDashboard.vue";

Exporting(Highcharts);

Chart.register(LinearScale, SankeyController, Flow);

export default {
  name: "WorkflowsOverview",

  components: {
    Portal,
    ItemActions,
    WorkflowFilter,
    OverviewReportCharts,
    BPMSReportChart,
    Sheet,
    RenderForm,
    SignatureField,
    Modal,
    Confirm,
    TextBuilderField,
    PasswordField,
    CheckboxField,
    TextAreaField,
    SingleChoiceField,
    FileIcon,
    ListItem,
    SelectField,
    VueHighcharts,
    WorkflowKanban,
    AddTask,
    FileDetails,
    EmailSharing,
    ProcessHistory,
    SubWorkflowProcess,
    FormEntryHistory,
    // Stepper,
    Attachments,
    Comments,
    UnpinDashboard,
  },

  data() {
    return {
      showItemActions: false,
      activeView: "CHART",
      workflowId: 0,
      workflowList: [],
      refreshInbox: false,
      filter: [],
      Highcharts: Highcharts,
      sankeyCharts: {
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        accessibility: {
          point: {
            valueDescriptionFormat:
              "{index}. {point.from} to {point.to}, {point.weight}.",
          },
        },
        series: [
          {
            keys: ["from", "to", "weight"],
            data: [],
            type: "sankey",
          },
        ],
      },
      sankeyChartsFilter: [],
      tableReport: [],
      tableReportData: [],
      dataViews: [
        {
          id: this.$nano.id(),
          label: "Sankey Diagram",
          value: "SANKEY",
          icon: "mdi-chart-sankey-variant",
          activeIcon: "mdi-chart-sankey-variant",
        },
        {
          id: this.$nano.id(),
          label: "Table View",
          value: "TABLE",
          icon: "mdi-table",
          activeIcon: "mdi-table",
        },
      ],
      dataView: "SANKEY",
      columns: [],
      search: "",
      slaSettings: 0,
      formFields: [],
      superUser: false,
      initiatedBy: "",
      formId: "",
      repositoryId: 0,
      repositoryFields: [],
      processId: 0,
      workflow: {},
      workflowRequestList: [],
      form: null,
      formModel: {},
      formEditAccess: false,
      formEditControls: [],
      readonlyControls: [],
      formVisibilityAccess: true,
      formSecureControls: [],
      workflowFormModel: {},
      saveAction: "",
      generatePDF: 0,
      attachmentLoad: null,
      attachmentList: [],
      attachmentSheet: false,
      commentsLoad: null,
      commentText: "",
      commentsList: [],
      commentsSheet: false,
      selectedProcess: {},
      selectedFile: {},
      fileSheet: false,
      fileDetailsSheet: false,
      fileData: [],
      checkList: [],
      rowIndex: null,
      hideFileSheet: false,
      processSheet: false,
      totalItems: 0,
      itemsPerPage: 10,
      currentPage: 1,
      title: "NO DATA",
      tabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "print",
          value: "PRINT",
          icon: "mdi-printer",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "task",
          value: "TASK",
          icon: "eva-cube-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Data Validation",
          value: "VALIDATION",
          icon: "mdi-file-compare",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Sub Process",
          value: "SUB_PROCESS",
          icon: "eva-link-2-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Form Entry History",
          value: "FORM_ENTRY_HISTORY",
          icon: "mdi-file-document",
          count: 0,
        },
      ],
      taskSheet: false,
      headerTitle: "",
      taskFormId: 0,
      taskRepositoryId: 0,
      addTaskForm: {},
      addTaskSheet: false,
      taskEntryId: [],
      taskEntries: [],
      taskEntryList: [],
      taskMode: "",
      selectedTask: {},
      filterBy: [],
      processActionsAddOn: [],
      advancedCommentsSheet: false,
      commentActions: [
        {
          id: this.$nano.id(),
          label: "Internal",
          value: 2,
        },
        {
          id: this.$nano.id(),
          label: "Public",
          value: 1,
        },
      ],
      commentAction: 1,
      advancedCommentText: "",
      actionText: "",
      actionRemarks: false,
      actionPassword: false,
      actionPasswordConfirm: false,
      actionConfirmAlert: false,
      actionUserPassword: "",
      isLoading: false,
      internalForward: {
        proceed: false,
        users: [],
        groups: [],
      },
      userSignature: false,
      signImg: "",
      signList: [],
      actions: [],
      itemModel: {},
      checkListRowIndex: null,
      selectAll: false,
      userList: [],
      groupList: [],
      processForwardGroupAction: false,
      processForwardModal: false,
      forwardUsers: [],
      forwardGroups: [],
      errorMessage: "",
      alertModal: false,
      errorModal: false,
      documentActionList: [
        // {
        //   id: "Email_Sharing",
        //   key: "emailSharing",
        //   label: "Email Sharing",
        //   icon: "mdi-email-outline",
        // },
        {
          id: "Download",
          key: "download",
          label: "Download",
          icon: "mdi-download-outline",
        },
        {
          id: "Print",
          key: "print",
          label: "Print",
          icon: "eva-printer-outline",
        },
      ],
      activeKanbanView: "LIST",
      isVisibleKanbanView: false,
      showKanbanView: false,
      kanbanSettings: [],
      kanbanSettingsList: [],
      workflowName: "",
      workflowProcessList: [],
      addTaskEnabled: false,
      loadingBarContent: false,
      emailSharingSheet: false,
      selectedFileList: [],
      fileNames: [],
      selectedFileForAttach: 0,
      attachComment: false,
      displayInternalPublic: false,
      historyProcessId: 0,
      notifyInitiator: false,
      formView: true,
      stageLevel: [],
      currentStep: 0,
      hasWflowInitiator: false,
      subWorkflowId: 0,
      initiateSubWorkflowProcess: false,
      subProcessSheet: false,
      subProcessData: {},
      subTabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
      ],
      subProcessLink: "",
      openSubProcessSheet: false,
      subProcessTickets: [],
      subProcessId: 0,
      showFormEntryHistoryModal: false,
      showAttachmentSheet: false,
      wform: {},
      repositoryDetails: {},
      showCommentsSheet: false,
      repositoryName: "",
      listedData: [],
      pined: false,
      isDeleteConfirmationVisible: false,
      deleteData: {},
      deleteName: "",
    };
  },

  computed: {
    panels() {
      if (!this.form) {
        return [];
      }

      return this.form.panels;
    },

    secondaryPanels() {
      if (!this.form) {
        return [];
      }

      return this.form.secondaryPanels;
    },

    formSettings() {
      if (!this.form) {
        return {};
      }
      return this.form.settings;
    },

    checkListTemplate() {
      if (this.checkList.length) {
        return this.checkList.filter((row) => row.template).length > 0;
      }
      return false;
    },

    selectedFileCount() {
      let count = this.attachmentList.filter((item) => item.checked).length;
      return count;
    },

    isSelectedPDF() {
      let filePDF = true;
      this.attachmentList.forEach((item) => {
        let ext = item.name.split(".").pop();
        if (item.checked && ext.toLowerCase() !== "pdf") {
          filePDF = false;
        }
      });
      return filePDF;
    },

    _enableControls() {
      let enableControls = [];
      let listControls = [];
      if (this.panels) {
        this.panels.forEach((panel) => {
          if (panel.fields.length) {
            let panelFields = panel.fields.filter(
              (field) =>
                field.type === "SINGLE_SELECT" ||
                field.type === "SINGLE_CHOICE" ||
                field.type === "MULTIPLE_CHOICE" ||
                field.type === "MULTI_SELECT"
            );
            if (panelFields.length) {
              panelFields.forEach((row) => {
                if (row.settings.validation.enableSettings) {
                  if (row.settings.validation.enableSettings.length) {
                    let controls = [];
                    row.settings.validation.enableSettings.forEach((tblrow) => {
                      if (tblrow.controls.length) {
                        controls.push({
                          value: tblrow.value,
                          childControls: tblrow.controls,
                        });
                        tblrow.controls.forEach((control) => {
                          if (controls.indexOf(control) < 0) {
                            listControls.push(control);
                          }
                        });
                      }
                    });
                    if (controls.length) {
                      enableControls.push({
                        id: row.id,
                        parentControls: controls,
                      });
                    }
                  }
                }
              });
            }
          }
        });
      }
      return {
        listControls: listControls,
        controls: enableControls,
      };
    },

    customChart() {
      let origin = location.origin;
      if (
        origin === "https://demo.ezofis.com" ||
        origin === "http://52.172.32.88"
      ) {
        if (this.workflowId === 2077 || this.workflowId === 2092) {
          return true;
        }
      } else if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "http://localhost:8080"
      ) {
        if (this.workflowId === 30 || this.workflowId === 55) {
          return true;
        }
      }
      return false;
    },

    checkCommentsOption() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        origin === "http://localhost:80801"
      ) {
        return false;
      } else if (
        (origin === "https://app.ezofis.com1" ||
          origin === "https://appuat.ezofis.com") &&
        this.$store.state.session.tenantId === 2
      ) {
        if (Object.keys(this.selectedProcess).length) {
          if (this.selectedProcess.jiraIssueInfo.id) {
            return true;
          }
        }

        return false;
      }
      return true;
    },

    commentsSheetHeight() {
      if (this.checkTenant()) {
        if (this.attachmentList.length && this.fileNames.length) {
          if (this.attachComment && this.displayInternalPublic) {
            return "calc(100vh - 270px)";
          } else if (this.attachComment && !this.displayInternalPublic) {
            return "calc(100vh - 230px)";
          } else if (!this.attachComment && this.displayInternalPublic) {
            return "calc(100vh - 225px)";
          } else {
            return "calc(100vh - 200px)";
          }
        } else if (this.displayInternalPublic) {
          return "calc(100vh - 220px)";
        } else if (this.selectedProcess.stageType !== "START") {
          return "calc(100vh - 180px)";
        } else {
          return "calc(100vh - 150px)";
        }
      } else {
        return "calc(100vh - 150px)";
      }
    },

    notifyInitiatorClass() {
      return !(this.attachmentList.length && this.fileNames.length)
        ? "action-container"
        : "";
    },
  },

  watch: {
    filter: {
      immediate: true,
      deep: true,
      handler() {
        if (this.filter.length) {
          if (this.filter[this.filter.length - 1]) {
            if (this.activeView === "CHART") {
              try {
                this.$refs.refCharts.removeSeries();
              } catch (e) {
                //
              }
            }
          }
        }
      },
    },

    workflowId: {
      immediate: true,
      deep: true,
      async handler() {
        this.workflow = {};
        if (this.workflowId) {
          this.getKanbanViewSettings();

          this.getWorkflowData();

          let selectedWorkflow = this.workflowList.find(
            (item) => item.value === this.workflowId
          );
          let name = this.workflowList.find(
            (item) => item.value === this.workflowId
          );
          this.workflowName = name.label;
          this.initiatedBy = selectedWorkflow.initiatedBy;
          this.formId = "";
          this.formFields = [];
          this.repositoryId = 0;
          this.repositoryFields = [];
          await this.getWorkflow();

          if (
            this.initiatedBy === "FORM" ||
            this.initiatedBy === "DOCUMENT_FORM"
          ) {
            this.formId = String(selectedWorkflow.formId);
            if (selectedWorkflow.formId) {
              await this.getForm();
            }
            this.repositoryId = selectedWorkflow.repositoryId;
            if (selectedWorkflow.repositoryId) {
              this.getRepository();
            }
          } else if (this.initiatedBy === "DOCUMENT") {
            this.repositoryId = selectedWorkflow.repositoryId;
            if (selectedWorkflow.repositoryId) {
              await this.getRepository();
            }
          }

          if (this.activeView === "SANKEY") {
            this.formFields.forEach((field) => {
              if (this.sankeyChartsFilter.length < 2) {
                if (field.dataType === "SINGLE_SELECT") {
                  this.sankeyChartsFilter.push(field.value);
                }
              }
            });
            if (this.sankeyChartsFilter.length) {
              await this.getFilterList();
              this.overviewChart();
              this.overviewTableReport();
            }
          }
        }
      },
    },

    activeView: {
      immediate: true,
      deep: true,
      handler() {
        if (this.activeView === "CHART") {
          try {
            this.$refs.refCharts.removeSeries();
          } catch (e) {
            //
          }
        } else if (this.activeView === "SANKEY" && this.workflowId) {
          this.formFields.forEach((field) => {
            if (this.sankeyChartsFilter.length < 2) {
              if (field.dataType === "SINGLE_SELECT") {
                this.sankeyChartsFilter.push(field.value);
              }
            }
          });
          if (this.sankeyChartsFilter.length) {
            this.overviewChart();
            this.overviewTableReport();
          }
        }
      },
    },

    dataView: {
      immediate: true,
      deep: true,
      handler() {
        if (this.activeView === "SANKEY") {
          if (this.dataView === "SANKEY") {
            this.overviewChart();
          } else if (this.activeView === "TABLE") {
            this.overviewTableReport();
          }
        }
      },
    },

    workflowFormModel: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workflowFormModel && this.saveAction) {
          this.checkRequiredDocument();
        }
      },
    },

    attachmentSheet: {
      immediate: true,
      handler() {
        if (this.attachmentSheet && this.selectedProcess.processId) {
          if (this.attachmentList.length) {
            let index = this.attachmentList.length - 1;
            this.getFileData(this.attachmentList[index], "file");
          }
          this.attachmentLoad = setInterval(() => {
            this.getAttachments();
          }, 30000);
        } else {
          clearInterval(this.attachmentLoad);
        }
      },
    },

    commentsSheet: {
      immediate: true,
      handler() {
        if (this.commentsSheet && this.selectedProcess.processId) {
          this.commentsLoad = setInterval(() => {
            this.getComments();
          }, 30000);
        } else {
          clearInterval(this.commentsLoad);
        }
      },
    },

    subWorkflowId() {
      if (this.subWorkflowId) {
        let subWorkflow = this.workflowList.find(
          (workflow) => workflow.value == this.subWorkflowId
        );
        if (subWorkflow) {
          this.subWorkflowName = `Initiate - ${subWorkflow.label}`;
          this.hasWflowInitiator = false;
          let wflowJson = subWorkflow.flowJson;
          let startBlock = wflowJson.blocks.find(
            (block) => block.type === "START"
          );

          if (startBlock) {
            if (
              startBlock.settings.initiateMode === "MANUAL" &&
              subWorkflow.initiatedBy !== "DOCUMENT"
            ) {
              if (
                startBlock.settings.users.indexOf(
                  this.$store.state.session.id
                ) > -1 ||
                startBlock.settings.users.indexOf("0") > -1
              ) {
                this.hasWflowInitiator = true;
              }

              if (startBlock.settings.initiateBy.indexOf("DOMAIN_USER") > -1) {
                let domain = this.$store.state.session.email.split("@").pop();

                if (startBlock.settings.userDomains.indexOf(domain) > -1) {
                  this.hasWflowInitiator = true;
                }
              }
            }
          }
        } else {
          this.hasWflowInitiator = false;
        }
      }
    },

    taskEntries: {
      immediate: true,
      handler() {
        this.tabs.find((tab) => {
          if (tab.value === "TASK") {
            tab.count = this.taskEntries.length;
          }
        });
      },
    },
  },

  created() {
    this.getUserWorkflow();
    this.getUsers();
    this.getGroups();
  },

  mounted() {
    this.showItemActions = true;
  },

  methods: {
    isTenantArmgroup() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return true;
      }
      return false;
    },

    checkTabAccess(value) {
      if (value === "TASK") {
        return this.addTaskEnabled;
      } else if (value === "VALIDATION") {
        if (this.isTenantArmgroup()) {
          return true;
        } else {
          return false;
        }
      } else if (value === "FORM_ENTRY_HISTORY") {
        if (this.formId) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },

    clearAttach() {
      this.selectedFileForAttach = 0;
      this.attachComment = false;
      this.notifyInitiator = false;
    },

    searchRow(search) {
      if (search) {
        this.tableReport = this.tableReportData.filter((row) => {
          for (let cell in row) {
            let index = this.columns.findIndex((column) => {
              return column.name === cell;
            });
            if (index >= 0 && row[cell]) {
              if (
                String(row[cell]).toLowerCase().includes(search.toLowerCase())
              ) {
                return row;
              }
            }
          }
        });
      } else {
        this.tableReport = this.tableReportData;
      }
    },

    export_Data() {
      if (this.tableReportData.length) {
        exportData(this.tableReportData);
      }
    },

    refreshData() {
      if (this.activeView === "CHART") {
        try {
          this.$refs.refCharts.removeSeries();
        } catch (e) {
          //
        }
      } else if (this.activeView === "SANKEY") {
        this.overviewChart();
        this.overviewTableReport();
      }
    },

    loadingOverviewChart() {
      this.overviewChart();
      this.overviewTableReport();
    },

    checkTenant() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        this.commentAction = 2;
        return false;
      }
      return true;
    },

    removeTags(value) {
      if (!value) return "";
      return value.replace(/(<([^>]+)>)/gi, "");
    },

    tableValue(value) {
      if (value) {
        return JSON.parse(value);
      }
      return [];
    },

    duration(duration) {
      const date = duration instanceof Date ? duration : new Date(duration);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    validateURL(value) {
      let urlPattern =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return urlPattern.test(value);
    },

    validateTypeValue(value) {
      if (value) {
        try {
          var jsonConvert = JSON.parse(value);
          if (typeof jsonConvert === "object") {
            return jsonConvert;
          }
        } catch (e) {
          return value;
        }
      }
      return value;
    },

    closeAttachmentsSheet() {
      this.attachmentSheet = false;
    },

    closeCommentsSheet() {
      this.closeCommnetsSheet = false;
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    fileSize(size) {
      return formatBytes(size);
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();
        if (this.selectedFile.initiate) {
          if (pdfSupport(ext)) {
            return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
          } else if (origin === "http://52.172.32.88") {
            return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
          } else {
            return `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/1`;
          }
        } else {
          if (pdfSupport(ext)) {
            return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}&action=download,print`;
          } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
            return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2`;
          } else {
            return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
          }
        }
      }
    },

    fileSelectAll() {
      this.attachmentList = this.attachmentList.map((item) => {
        return {
          ...item,
          checked: this.selectAll,
        };
      });
    },

    itemLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&type=2&filename=${this.itemModel.name}`;
      } else if (origin === "https://trial.ezofis.com") {
        return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&type=2&filename=${this.itemModel.name}&action=all`;
      } else if (origin === "https://app.ezofis.com1") {
        return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&type=2&filename=${this.itemModel.name}&action=all`;
      } else {
        return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.itemModel.name}&action=all`;
      }
    },

    openFile(fileObj) {
      this.selectedFile = fileObj;
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        window.open(this.fileLink());
      } else {
        this.fileSheet = true;
      }
    },

    download(id) {
      window.open(
        `${process.env.VUE_APP_API_URL}/workflow/viewChecklist?wId=${this.workflowId}&id=${id}`
      );
    },

    downloadAll() {
      this.checkList.forEach((row) => {
        if (row.id && row.template) {
          window.open(
            `${process.env.VUE_APP_API_URL}/workflow/viewChecklist?wId=${this.workflowId}&id=${row.id}`
          );
        }
      });
    },

    attachTemplate(uid) {
      this.rowIndex = uid;
      this.$refs.attachments.click();
    },

    lastActionDuration(comment) {
      const date =
        comment.createdAt instanceof Date
          ? comment.createdAt
          : new Date(comment.createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    closeTaskSheet() {
      this.taskSheet = false;
    },

    closeProcessSheet() {
      this.processSheet = false;
      this.subProcessData = {};
      this.subProcessTickets = [];
    },

    addSankeyChartsFilter() {
      this.sankeyChartsFilter.push("");
    },

    removeSankeyChartsFilter(index) {
      this.sankeyChartsFilter.splice(index, 1);
      this.refreshData();
    },

    addTask() {
      this.selectedTask = {};
      this.taskMode = "NEW";
      this.addTaskSheet = true;
    },

    viewTask(id) {
      let filterTask = this.taskList.filter((row) => row.formEntryId === id);
      if (filterTask.length) {
        this.selectedTask = filterTask[0].entryInfo[0];
        this.selectedTask = {
          id: filterTask[0].formEntryId,
          ...filterTask[0].entryInfo[0],
        };
      }
      this.taskMode = "EDIT";
      this.addTaskSheet = true;
    },

    addTaskEntry(formId, entryId) {
      if (this.selectedProcess.processId) {
        this.insertTaskEntry(formId, entryId);
      } else {
        this.taskEntryId.push({
          formId: formId,
          formEntryId: entryId,
        });
        this.getTaskFormEntry(formId, entryId);
      }
    },

    getLabel(fieldId) {
      let controlLabel = "";
      if (this.form) {
        this.form.panels.forEach((panel) => {
          if (!panel.fields.length) {
            return;
          }
          let field = panel.fields.find((field) => field.id === fieldId);
          if (field) {
            controlLabel = field.label ? field.label : field.type;
            return;
          }
        });
      }
      return controlLabel;
    },

    getColor(label) {
      if (label === "Closed" || label === "High") {
        return "red";
      } else if (label === "In Progress" || label === "Medium") {
        return "warning";
      } else if (label === "Open" || label === "Low") {
        return "secondary";
      } else if (label === "ReOpen") {
        return "primary";
      } else {
        return "primary";
      }
    },

    async getUserWorkflow() {
      this.workflowList = [];
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getUserWorkflow();
      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload.length) {
        payload.forEach((workflow) => {
          this.workflowList.push({
            id: this.$nano.id(),
            label: workflow.name,
            value: workflow.id,
            initiatedBy: workflow.initiatedBy,
            formId: workflow.wFormId,
            repositoryId: workflow.repositoryId,
            flowJson: JSON.parse(workflow.flowJson),
          });
        });
        this.getTaskForm();
      }
    },

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      if (payload) {
        payload.map((user) => {
          if (user.id !== this.$store.state.session.id)
            this.userList.push({
              id: this.$nano.id(),
              label: user.value || user.loginName,
              value: user.id,
            });
        });
      }
    },

    async getGroups() {
      const { error, payload } = await group.getGroupList();

      if (error) {
        this.$alert.error("Error fetching groups");
        return;
      }

      this.groupList =
        payload &&
        payload.map((group) => ({
          id: this.$nano.id(),
          label: group.value,
          value: group.id,
        }));
    },

    async overviewChart() {
      this.pined = false;
      this.loadingBarContent = true;
      // this.$store.commit("showLoadingBar");
      try {
        this.$refs.refCharts.removeSeries();
      } catch (e) {
        //
      }
      const { error, payload } = await workflow.overviewChart(
        this.workflowId,
        this.sankeyChartsFilter
      );

      this.loadingBarContent = false;
      // this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.listedData.length) {
        this.listedData.forEach((item) => {
          let parse = JSON.parse(item.filterJson);
          let filterParse = parse[0].filters.toString();
          let stringFilter = JSON.stringify([...this.sankeyChartsFilter]);
          let id = parse[0].uId;
          if (id === this.workflowId && stringFilter === filterParse) {
            this.pined = true;
          }
        });
      }
      if (payload.length) {
        this.sankeyCharts.series[0].data = payload;
        this.sankeyCharts.series[0].name = workflow.name;
        this.$refs.refCharts.addSeries(this.sankeyCharts.series[0]);
      }
    },

    async overviewTableReport() {
      this.columns = [];
      this.loadingBarContent = true;
      // this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.overviewTableReport(
        this.workflowId,
        this.sankeyChartsFilter
      );
      this.loadingBarContent = false;
      // this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.listedData.length) {
        this.listedData.forEach((item) => {
          let parse = JSON.parse(item.filterJson);
          let filterParse = parse[0].filters.toString();
          let stringFilter = JSON.stringify([...this.sankeyChartsFilter]);
          let id = parse[0].uId;
          if (id === this.workflowId && stringFilter === filterParse) {
            this.pined = true;
          }
        });
      }
      if (payload) {
        if (payload.Table.length) {
          this.tableReport = payload.Table;
          this.tableReportData = this.tableReport;
          let keys = Object.keys(this.tableReport[0]);
          keys.forEach((key) => {
            this.columns.push({
              id: this.$nano.id(),
              name: key,
            });
          });
        }
      }
    },

    async getWorkflow() {
      this.loadingBarContent = true;
      // this.$store.commit("showLoadingBar");

      const { error, payload } = await workflow.getWorkflow(this.workflowId);

      // this.$store.commit("hideLoadingBar");
      this.loadingBarContent = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.workflow = payload;
      let workflowJson = JSON.parse(this.workflow.flowJson);

      this.slaSettings = workflowJson.hasSLASettings;

      if (workflowJson.settings.general.coordinator.length) {
        workflowJson.settings.general.coordinator.forEach((user) => {
          if (user === this.$store.state.session.id) this.superUser = true;
        });
      }
      if (workflowJson.settings.general.superuser.length) {
        workflowJson.settings.general.superuser.forEach((user) => {
          if (user === this.$store.state.session.id) this.superUser = true;
        });
      }

      let actionBlock = workflowJson.blocks.find(
        (block) => block.id === this.selectedProcess.activityId
      );
      if (actionBlock) {
        this.headerTitle = `${this.selectedProcess.requestNo} - ${actionBlock.settings.label}`;
        if (actionBlock.settings.formEditAccess === "") {
          this.formEditAccess = true;
          this.formEditControls = [];
        } else if (actionBlock.settings.formEditAccess === "FULL") {
          this.formEditAccess = false;
          this.formEditControls = [];
        } else if (actionBlock.settings.formEditAccess === "PARTIAL") {
          this.formEditAccess = false;
          this.formEditControls = actionBlock.settings.formEditControls;
        }
        if (actionBlock.settings.formVisibilityAccess) {
          this.formVisibilityAccess = true;
          this.formSecureControls = [];
        } else {
          this.formVisibilityAccess = false;
          actionBlock.settings.formSecureControls.forEach((control) => {
            try {
              let object = JSON.parse(control);
              if (object.userId === this.$store.state.session.id) {
                this.formSecureControls.push(...object.formFields);
              }
            } catch (e) {
              this.formSecureControls.push(control);
            }
          });
        }
        this.actions = this.processActions;
      }
    },

    async getForm() {
      this.formFields = [];
      if (this.formId) {
        this.loadingBarContent = true;
        // this.$store.commit("showLoadingBar");

        const { error, payload } = await form.getForm(this.formId);

        // this.$store.commit("hideLoadingBar");
        this.loadingBarContent = false;

        if (error) {
          this.$alert.error(error);
          return;
        }

        let formJson = JSON.parse(payload.formJson);
        this.form = formJson;
        this.wform = payload;
        const panels = [...formJson.panels, ...formJson.secondaryPanels];

        if (this.form.settings.general.layout === "CARD") {
          this.form.settings.general.layout = "TAB";
        }

        if (!panels.length) {
          return;
        }
        let fields = [];
        for (const panel of panels) {
          fields.push(...panel.fields);
        }

        fields.forEach((field) => {
          this.formFields.push({
            id: this.$nano.id(),
            label: field.label ? field.label : field.type,
            value: field.id,
            dataType: field.type,
          });
        });
      }
    },

    async getRepository() {
      this.repositoryFields = [];

      const { error, payload } = await repository.getRepository(
        this.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      let repositoryDetails = payload;
      this.repositoryName = payload.name;
      this.repositoryFields = repositoryDetails.fields;
      this.repositoryDetails = repositoryDetails;
    },

    async getTaskForm() {
      this.taskFormList = [];
      this.addTaskEnabled = false;
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "Task",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 50,
        currentPage: 1,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;
      if (data.length) {
        if (data[0].value.length) {
          this.addTaskEnabled = true;
          data[0].value.forEach((task) => {
            this.taskFormList.push({
              id: task.id,
              label: task.name,
              value: task.id,
              repositoryId: task.repositoryId,
              formJson: task.formJson,
            });
          });
          if (this.taskFormList.length) {
            if (this.taskFormList.length === 1) {
              this.taskFormId = this.taskFormList[0].id;
              this.taskRepositoryId = this.taskFormList[0].repositoryId;
              this.addTaskForm = JSON.parse(this.taskFormList[0].formJson);
            }
          } else {
            //this.$alert.info("Task form not found");
          }
        }
      }
    },

    async openProcess(request) {
      this.processActions = [];
      this.processActionsAddOn = [];
      this.internalForward = {
        proceed: false,
        users: [],
        groups: [],
      };
      this.userSignature = false;
      this.checkList = [];
      this.form = {};
      this.formModel = {};
      this.itemModel = {};
      this.hasWflowInitiator = false;
      this.selectedProcess = request;
      let selectedWorkflow = this.workflowList.find(
        (workflow) => workflow.value == this.selectedProcess.id
      );
      this.workflow = selectedWorkflow;
      let wflowJson = selectedWorkflow.flowJson;
      if (selectedWorkflow.formId) {
        this.formId = String(selectedWorkflow.formId);
        await this.getForm();
      }
      this.actions = [];
      this.processActionsAddOn = [];

      let origin = location.origin;
      if (
        (origin === "https://ag-appsvc01.azurewebsites.net" ||
          origin === "https://ag-appsvc05.azurewebsites.net" ||
          origin === "http://localhost:8080") &&
        this.selectedProcess.activityId === "psyCrYXtKfcs-EJmrYIhv"
      ) {
        wflowJson.rules.forEach((rule) => {
          if (
            rule.fromBlockId === this.selectedProcess.activityId &&
            rule.proceedAction === "Force Close"
          ) {
            this.actions.push(rule.proceedAction);
            this.processActionsAddOn.push({
              action: rule.proceedAction,
              remarks: rule.remarks || false,
              passwordAccess: rule.passwordAccess || false,
              confirm: rule.confirm || false,
            });
          }
        });
      } else {
        wflowJson.rules.forEach((rule) => {
          if (rule.fromBlockId === this.selectedProcess.activityId) {
            if (this.actions.indexOf(rule.proceedAction) < 0) {
              this.actions.push(rule.proceedAction);
              this.processActionsAddOn.push({
                action: rule.proceedAction,
                remarks: rule.remarks || false,
                passwordAccess: rule.passwordAccess || false,
                confirm: rule.confirm || false,
              });
            }
          }
        });
      }

      wflowJson.blocks.forEach((block) => {
        if (block.id === this.selectedProcess.activityId) {
          if (
            this.selectedProcess.lastAction === "Forward" &&
            block.settings.forwardedUserAction === "REPLY"
          ) {
            this.actions = [];
            this.processActionsAddOn = [];
            this.actions.push(
              "Reply to " + this.selectedProcess.transaction_createdByEmail
            );
            this.processActionsAddOn.push({
              action:
                "Reply to " + this.selectedProcess.transaction_createdByEmail,
              remarks: true,
              passwordAccess: false,
              confirm: false,
            });
            this.customReply = true;
          } else {
            if (
              block.settings.userReply &&
              this.actions.indexOf("Reply") === -1
            ) {
              this.actions.push(
                "Reply to " + this.selectedProcess.lastActionStageName
              );
              this.userReply = true;
            }
            if (
              block.settings.toInitiator &&
              this.actions.indexOf("To Requester") === -1
            ) {
              this.actions.push("To Requester");
              this.toRequester = true;
            }
            if (block.settings.internalForward) {
              let origin = location.origin;
              if (
                (origin === "https://app.ezofis.com1" ||
                  origin === "https://appuat.ezofis.com") &&
                this.$store.state.session.tenantId === 2
              ) {
                this.actions.push("Assign");
              } else {
                this.actions.push("Forward");
              }
              this.internalForward.proceed = true;
              this.internalForward.users = [];
              this.internalForward.groups = [];
              if (block.settings.internalForwardUser) {
                if (block.settings.internalForwardUser.length) {
                  block.settings.internalForwardUser.forEach((user) => {
                    let userFilter = this.userList.filter(
                      (row) => row.value == user
                    );
                    if (userFilter.length) {
                      this.internalForward.users.push({
                        id: this.$nano.id(),
                        label: userFilter[0].label,
                        value: user,
                      });
                    }
                  });
                }
              }
              if (block.settings.internalForwardGroup) {
                if (block.settings.internalForwardGroup.length) {
                  block.settings.internalForwardGroup.forEach((group) => {
                    let groupFilter = this.groupList.filter(
                      (row) => row.value == group
                    );
                    if (groupFilter.length) {
                      this.internalForward.groups.push({
                        id: this.$nano.id(),
                        label: groupFilter[0].label,
                        value: group,
                      });
                    }
                  });
                }
              }
            }
          }

          this.formEditAccess = false;
          if (block.settings.formEditAccess === "") {
            this.formEditAccess = true;
            this.formEditControls = [];
          } else if (block.settings.formEditAccess === "FULL") {
            this.formEditAccess = false;
            this.formEditControls = [];
          } else if (block.settings.formEditAccess === "PARTIAL") {
            this.formEditAccess = false;
            this.formEditControls = block.settings.formEditControls;
          }
          if (block.settings.formVisibilityAccess) {
            this.formVisibilityAccess = true;
            this.formSecureControls = [];
          } else {
            this.formVisibilityAccess = false;
            this.formSecureControls = block.settings.formSecureControls;
          }

          if (
            wflowJson.settings.general.processNumberPrefix.indexOf("[") > -1
          ) {
            let requestNumberFormat = JSON.parse(
              wflowJson.settings.general.processNumberPrefix
            );
            this.readonlyControls = [];
            let requestColumn = requestNumberFormat.filter(
              (row) => row.key === "formColumn"
            );
            if (requestColumn.length) {
              requestColumn.forEach((row) => {
                this.readonlyControls.push(row.value);
                if (this.formEditControls.indexOf(row.value) > -1) {
                  this.formEditControls.splice(
                    this.formEditControls.indexOf(row.value),
                    1
                  );
                }
              });
            }
          }
          this.userSignature = block.settings.userSignature;
          if (block.settings.fileSettings) {
            if (block.settings.fileSettings.fileCheckList.length) {
              this.checkList = block.settings.fileSettings.fileCheckList;
              this.checkList.forEach((row) => {
                row.uid = this.$nano.id();
                row.attach = false;
              });
            }
          }
          if (block.settings.workflowInitiator) {
            this.subWorkflowId = block.settings.subWorkflowId;
          }
        }
      });
      if (this.selectedProcess.userType) {
        if (this.selectedProcess.userType === "SUPERUSER") {
          this.actions.push("Force Close");
        }
      }
      this.headerTitle = `${selectedWorkflow.label} - ${this.selectedProcess.requestNo} - ${this.selectedProcess.stage}`;
      if (this.selectedProcess.formData) {
        let keys = Object.keys(this.selectedProcess.formData.fields);
        let newValues = {};
        keys.forEach((key) => {
          newValues[key] = this.validateTypeValue(
            this.selectedProcess.formData.fields[key]
          );
        });
        this.formModel = newValues;
      } else if (this.selectedProcess.itemData) {
        this.itemModel = this.selectedProcess.itemData;
      }
      this.getAttachments();
      this.clearAttach();
      this.getComments();
      this.getProcessSignature();
      // this.showHistoryStepper(
      //   this.selectedProcess.processId,
      //   this.selectedProcess.id
      // );
      this.getSubWorkflow();
      this.getTaskList();
      this.processSheet = true;
    },

    validateRequest(action) {
      this.saveAction = action;
      if (this.itemModel.itemId) {
        this.processRequest();
      }
    },

    checkRequiredDocument() {
      let docRequired = this.checkList.filter(
        (row) => row.required && !row.attach
      );
      if (docRequired.length) {
        if (this.docWarning) {
          this.alertModal = true;
        } else {
          this.$alert.warning("Mandatory documents required");
          this.saveAction = "";
          this.workflowFormModel = {};
        }
      } else {
        this.proceedSubmission();
      }
    },

    proceedSubmission() {
      this.alertModal = false;
      this.processRequest();
    },

    closeAlertModal() {
      this.alertModal = false;
      this.saveAction = "";
      this.workflowFormModel = {};
    },

    async processRequest(forwardCheck = true, addOnCheck = true) {
      if (forwardCheck) {
        if (this.saveAction === "Forward" && this.internalForward.proceed) {
          this.processForwardGroupAction = false;
          this.processForwardModal = true;
          return;
        }
      }
      if (addOnCheck) {
        if (this.saveAction === "Save") {
          this.actionConfirm = true;
          this.actionConfirmAlert = true;
          return;
        } else {
          let actionAddOn = this.processActionsAddOn.find(
            (row) => row.action === this.saveAction
          );
          if (actionAddOn) {
            if (Object.keys(actionAddOn).length) {
              this.actionRemarks = actionAddOn.remarks;
              this.actionPassword = actionAddOn.passwordAccess;
              this.actionConfirm = actionAddOn.confirm;
              if (this.actionPassword) {
                this.actionPasswordConfirm = true;
                return;
              }
              if (this.actionRemarks) {
                this.actionComments = true;
                this.advancedCommentsSheet = true;
                return;
              }
              if (this.actionConfirm) {
                this.actionConfirmAlert = true;
                return;
              }
            }
          }
        }
      }
      let inputData = {};
      if (this.userReply && this.saveAction.indexOf("Reply") > -1) {
        this.saveAction = "UserReply";
      } else if (this.toRequester && this.saveAction === "To Requester") {
        this.saveAction = "ToInitiator";
      }
      if (this.customReply && this.saveAction.indexOf("Reply") > -1) {
        this.saveAction = "Reply";
        this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
      }

      if (this.formId) {
        inputData = {
          workflowId: this.selectedProcess.id,
          transactionId: this.selectedProcess.transactionId,
          review: this.saveAction === "Save" ? "" : this.saveAction,
          formData: {
            formId: this.formId,
            formEntryId: this.selectedProcess.formData.formEntryId,
            fields: this.workflowFormModel,
          },
          userIds: this.forwardUsers,
          groupIds: this.forwardGroups,
        };
      } else if (this.itemModel) {
        inputData = {
          workflowId: this.selectedProcess.id,
          transactionId: this.selectedProcess.transactionId,
          review: this.saveAction === "Save" ? "" : this.saveAction,
          formData: {},
          userIds: this.forwardUsers,
          groupIds: this.forwardGroups,
        };
      }
      this.saveAction = "";
      this.workflowFormModel = {};
      console.log(inputData);

      this.$store.commit("showLoadingBarSave");
      const { error, payload } = await workflow.processTransaction(inputData);
      this.$store.commit("hideLoadingBarSave");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.signImg) {
        await this.saveProcessSignature(this.selectedProcess);
      }

      if (inputData.review === "") {
        this.$alert.info(
          `${this.selectedProcess.requestNo} data saved, awaiting your action to submit`
        );
      } else if (inputData.review.toLowerCase().indexOf("reject") > -1) {
        this.$alert.danger(
          `${this.selectedProcess.requestNo} Request Rejected Successfully`
        );
      } else {
        this.$alert.success(
          `${this.selectedProcess.requestNo} Request Processed`
        );
      }
      this.forwardUsers = [];
      this.forwardGroups = [];
      if (this.subProcessId) {
        this.addSubWorkflowProcess(payload.processId);
      }
      this.closeProcessSheet();
      this.refreshInbox = true;
    },

    showLinks(tab) {
      if (tab === "ATTACHMENTS") {
        // this.attachmentSheet = true;
        this.showAttachmentSheet = true;
      } else if (tab === "COMMENTS") {
        this.displayInternalPublic = false;
        let selectedWorkflow = this.workflowList.find(
          (workflow) => workflow.value == this.selectedProcess.id
        );
        let wflowJson = selectedWorkflow.flowJson;
        let startBlock = wflowJson.blocks.find(
          (block) => block.type === "START"
        );
        if (startBlock) {
          if (startBlock.settings.initiateBy.indexOf("PORTAL") > -1) {
            this.displayInternalPublic = true;
          }
        }
        this.fileNames = [];
        this.fileNames = this.attachmentList
          .filter((item) => {
            let extension = item.name.split(".").pop().toUpperCase();

            return extension.toLowerCase() === "pdf";
          })
          .map((item) => {
            return {
              id: this.$nano.id(),
              label: item.name,
              value: item.id,
            };
          });
        // this.commentsSheet = true;
        this.showCommentsSheet = true;
      } else if (tab === "HISTORY" && this.selectedProcess.processId) {
        this.historyProcessId = this.selectedProcess.processId;
      } else if (tab === "PRINT") {
        if (this.selectedProcess.formData) {
          window.open(
            `${process.env.VUE_APP_API_URL}/form/generatePdfPrint/${this.$store.state.session.tenantId}/${this.selectedProcess.id}/${this.selectedProcess.processId}/${this.formId}/${this.selectedProcess.formData.formEntryId}/1`
          );
        } else if (this.selectedProcess.itemData) {
          window.open(
            `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedProcess.itemData.repositoryId}/${this.selectedProcess.itemData.itemId}/2`
          );
        }
        //window.print();
      } else if (tab === "TASK") {
        this.taskSheet = true;
      } else if (tab === "SUB_PROCESS") {
        this.subProcessSheet = true;
      } else if (tab === "FORM_ENTRY_HISTORY") {
        this.showFormEntryHistoryModal = true;
      }
    },

    openHistory(request) {
      this.selectedProcess = request;
      this.showLinks("HISTORY");
    },

    async openAttachments(request) {
      this.selectedProcess = request;
      await this.getAttachments();
      this.showLinks("ATTACHMENTS");
    },

    async openComments(request) {
      this.selectedProcess = request;
      this.clearAttach();
      await this.getAttachments();
      await this.getComments();
      this.showLinks("COMMENTS");
    },

    async getComments() {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = 0;
        }
      });
      this.commentsList = [];
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getProcessComments(
        this.selectedProcess.id,
        this.selectedProcess.processId
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.commentsList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "COMMENTS") {
            tab.count = this.commentsList.length;
          }
        });
      }
    },

    async saveComment() {
      if (this.commentText) {
        if (this.attachComment) {
          if (!this.selectedFileForAttach) {
            this.$alert.warning("Select File To Attach");
            return;
          }
        }
        if (this.selectedProcess.processId) {
          // this.$store.commit("showLoadingBarPage");
          const { error } = await workflow.insertProcessComment(
            this.selectedProcess.id,
            this.selectedProcess.processId,
            this.selectedProcess.transactionId,
            {
              comments: this.commentText,
              showTo: this.commentAction,
              hasNotifytoInitiated: this.notifyInitiator,
              embedJson: JSON.stringify({
                repositoryId: this.selectedProcess.repositoryId,
                itemIds: this.attachComment ? [this.selectedFileForAttach] : [],
              }),
            }
          );

          // this.$store.commit("hideLoadingBarPage");

          if (error) {
            this.$alert.error(error);
            return;
          }
          this.commentText = "";
          this.clearAttach();
          this.getComments();
        }
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText) {
        if (this.attachComment) {
          if (!this.selectedFileForAttach) {
            this.$alert.warning("Select File To Attach");
            return;
          }
        }
        if (this.selectedProcess.processId) {
          // this.$store.commit("showLoadingBarPage");
          const { error } = await workflow.insertProcessComment(
            this.selectedProcess.id,
            this.selectedProcess.processId,
            this.selectedProcess.transactionId,
            {
              comments: this.advancedCommentText,
              showTo: this.commentAction,
              hasNotifytoInitiated: this.notifyInitiator,
              embedJson: JSON.stringify({
                repositoryId: this.workflow.repositoryId,
                itemIds: this.attachComment ? [this.selectedFileForAttach] : [],
              }),
            }
          );

          // this.$store.commit("hideLoadingBarPage");

          if (error) {
            this.$alert.error(error);
            return;
          }
          // if (this.selectedProcess.jiraIssueInfo.id) {
          //   this.sendCommentsToJira(this.advancedCommentText);
          // }
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
          this.clearAttach();
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.advancedCommentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
          });
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
        }
        if (this.actionComments) {
          this.processRequest(false, false);
        }
      }
    },

    async uploadAttachments(e) {
      if (this.selectedProcess.processId) {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("workflowId", this.selectedProcess.id);
        formData.append("repositoryId", this.selectedProcess.repositoryId);
        formData.append("processId", this.selectedProcess.processId);
        formData.append("transactionId", this.selectedProcess.transactionId);
        formData.append("fields", "");
        formData.append("file", file);
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.checkListRowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.checkListRowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        }
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        // this.$store.commit("showLoadingBarPage");
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/workflow/attachmentWithProcessId`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          const { status, data } = response;
          if (status === 200 && data) this.$alert.success(`Document attached`);
          else throw response;
        } catch (error) {
          this.$alert.error("Error uploading file");
          console.error(error);
        }
        // this.$store.commit("hideLoadingBarPage");
        if (this.checkList.length) {
          this.checkList.forEach((row) => {
            if (row.uid === this.checkListRowIndex) {
              row.attach = true;
              return;
            }
          });
        }
        e.target.value = "";
      } else {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("repositoryId", this.workflow.repositoryId);
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.checkListRowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.checkListRowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        }
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });

          const { status, data } = response;
          if (status !== 200) {
            throw "Invalid response";
          }
          let filesList = JSON.parse(data);
          this.attachmentList.push({
            id: Number(filesList.fileId),
            name: filename,
            createdAt: new Date().toISOString(),
            createdByEmail: this.$store.state.session.email,
            size: file.size,
            initiate: true,
            uid: this.checkListRowIndex,
          });
          if (this.checkList.length) {
            this.checkList.forEach((row) => {
              if (row.uid === this.checkListRowIndex) {
                row.attach = true;
                return;
              }
            });
          }
          e.target.value = "";
        } catch (error) {
          console.error(error);
          this.$alert.error(`error attaching file`);
        }
      }
    },

    async getAttachments() {
      this.checkList = [];
      if (this.selectedProcess.processId) {
        let selectedWorkflow = this.workflowList.find(
          (workflow) => workflow.value == this.selectedProcess.id
        );
        let workflowJson = selectedWorkflow.flowJson;
        let block = workflowJson.blocks.find(
          (block) => block.id === this.selectedProcess.activityId
        );
        if (block) {
          if (block.settings.fileSettings) {
            if (block.settings.fileSettings.fileCheckList.length) {
              this.checkList = block.settings.fileSettings.fileCheckList;
              this.checkList.forEach((row) => {
                row.uid = this.$nano.id();
                row.attach = false;
              });
            }
          }
        }
        this.tabs.find((tab) => {
          if (tab.value === "ATTACHMENTS") {
            tab.count = 0;
          }
        });
        let count = this.attachmentList.length;
        // this.$store.commit("showLoadingBarPage");
        const { error, payload } = await workflow.getAttachments(
          this.selectedProcess.id,
          this.selectedProcess.processId
        );

        // this.$store.commit("hideLoadingBarPage");

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (payload.length) {
          if (count !== payload.length) {
            this.attachmentList = payload.map((item) => {
              return {
                ...item,
                checked: false,
              };
            });
          }
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length;
            }
          });
        } else {
          this.attachmentList = [];
        }
        if (this.checkList.length) {
          this.checkList.forEach((row) => {
            let filterAttach = this.attachmentList.filter((file) => {
              let filename = file.name.substr(0, file.name.lastIndexOf("."));
              if (row.name === filename) {
                return file;
              }
            });
            if (filterAttach.length) {
              row.attach = true;
            } else {
              row.attach = false;
            }
          });
        }
      }
    },

    async getFileData(file, data) {
      //this.$store.commit("showLoadingBar");
      const { error, payload } = await repository.getFileData({
        repositoryId: file.repositoryId,
        itemId: file.id,
      });
      //this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }
      if (file.fileData) {
        this.fileData = file.fileData;
      } else {
        this.fileData = payload;
      }
      if (data !== "file") this.fileDetailsSheet = true;
    },

    async saveProcessSignature(request) {
      let input = {
        signBinary: this.signImg.split(",")[1],
        fileType: "png",
      };
      const { error } = await workflow.saveProcessSignature(
        request.id,
        request.processId,
        request.transactionId,
        input
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.signImg = "";
    },

    async attachmentDelete(id) {
      this.$store.commit("showLoadingBar");
      const { error } = await repository.fileDelete(
        this.selectedProcess.repositoryId,
        id
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.getAttachments();
    },

    async getProcessSignature() {
      this.signList = [];
      const { error, payload } = await workflow.getProcessSignature(
        this.selectedProcess.id,
        this.selectedProcess.processId
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.signList = payload;
    },

    signLink(id) {
      return `${process.env.VUE_APP_API_URL}/workflow/signView/${this.$store.state.session.tenantId}/${this.selectedProcess.id}/${id}`;
    },

    async validatePassword() {
      const areAllFieldsValid = await this.$refs.passwordForm.validate();

      if (!areAllFieldsValid) {
        return;
      }
      this.isLoading = true;
      const { error } = await auth.validatePassword({
        password: this.actionUserPassword,
      });
      this.isLoading = false;
      if (error) {
        console.log(error);
        this.$alert.warning(error);
        return;
      }
      this.actionUserPassword = "";
      this.actionPasswordConfirm = false;
      if (this.actionRemarks) {
        this.actionComments = true;
        this.advancedCommentsSheet = true;
      } else {
        this.actionRemarks = false;
        this.actionPassword = false;
        this.processRequest(false, false);
      }
    },

    documentAction(action) {
      if (action.id === "Email_Sharing") {
        //
      } else if (action.id === "Print") {
        if (this.selectedFile.name) {
          let ext = this.selectedFile.name.split(".").pop();
          if (pdfSupport(ext)) {
            localStorage.setItem("filePrint", true);
            localStorage.setItem("filePrintId", this.selectedFile.id);
          } else {
            this.$refs.myFiles.contentWindow.print();
          }
        }
        this.filePrintSession();
      } else if (action.id === "Download") {
        let link = "";
        link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    async filePrintSession() {
      const { error } = await menu.filePrintSession({
        repositoryId: this.selectedFile.repositoryId,
        itemId: this.selectedFile.id,
        activityId: 4,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async documentMerge() {
      let fileIds = [];
      this.attachmentList.forEach((item) => {
        let ext = item.name.split(".").pop();
        if (item.checked && ext.toLowerCase() === "pdf") {
          fileIds.push(item.id);
        }
      });
      let input = {
        ids: fileIds,
      };
      const { error } = await workflow.documentMerge(
        this.workflowId,
        this.selectedProcess.processId,
        this.selectedProcess.transactionId,
        this.workflow.repositoryId,
        input
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$alert.success("Document merge process initiated");
    },

    async documentShare() {
      this.selectedFileList = [];
      this.attachmentList.forEach((item) => {
        if (item.checked) {
          this.selectedFileList.push({
            id: item.id,
            name: item.name,
          });
        }
      });
      this.emailSharingSheet = true;
    },

    async getKanbanViewSettings() {
      this.loadingBarContent = true;
      // this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getKanbanViewSettings(
        this.workflowId
      );
      this.loadingBarContent = false;
      // this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.kanbanSettings = [];
      this.kanbanSettingsList = [];
      if (payload.length) {
        this.isVisibleKanbanView = true;
        payload.forEach((item) => {
          this.kanbanSettings.push({
            ...item,
            search: "",
          });
          this.kanbanSettingsList.push({
            ...item,
            search: "",
          });
        });
        // this.kanbanSettingsList = this.kanbanSettings;
      } else {
        this.isVisibleKanbanView = false;
      }
    },

    showKanbanSettings() {
      this.activeKanbanView = "kanban";
      this.showKanbanView = true;
      this.activeView = "KANBAN";
    },

    async getWorkflowData() {
      this.loadingBarContent = true;
      // this.$store.commit("showLoadingBar");
      this.workflowProcessList = [];

      // this.list = [];
      const { error, payload } = await report.getWorkflowData(this.workflowId, {
        filterBy: [],
        itemsPerPage: 100,
        currentPage: 1,
        mode: "",
      });

      this.loadingBarContent = false;
      // this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          this.workflowProcessList.push({
            ...process,
          });
        });
      });

      this.totalItems = payload.meta?.totalItems || 0;
    },

    searchKanban(search, list, index) {
      console.log(list, search);
      if (search) {
        let columns = [
          "attachmentList",
          "commentsList",
          "completedStatus",
          "currentActionDate",
          "currentActionUser",
          "currentTransactionId",
          "nodeName",
          "processId",
          "requestNo",
          "slaTime",
        ];

        // console.log(columns);

        let data = this.kanbanSettingsList[index].tickets.filter((row) => {
          for (let cell in row) {
            let index1 = columns.indexOf(cell);
            if (index1 >= 0 && row[cell]) {
              if (
                String(row[cell]).toLowerCase().includes(search.toLowerCase())
              ) {
                return row;
              }
            }
          }
        });
        // console.log(data);

        this.kanbanSettings[index].tickets = data;
      } else {
        this.kanbanSettings[index].tickets =
          this.kanbanSettingsList[index].tickets;
      }
      // console.log(this.kanbanList);
    },

    async getTaskList() {
      this.taskEntries = [];
      let taskFilterBy = [];
      const panels = [
        ...this.addTaskForm.panels,
        ...this.addTaskForm.secondaryPanels,
      ];
      let formFields = [];
      for (const panel of panels) {
        formFields.push(...panel.fields);
      }
      let taskOwnerField = formFields.find(
        (field) => field.label === "Task Owner"
      );
      if (taskOwnerField) {
        taskFilterBy.push({
          filters: [
            {
              criteria: taskOwnerField.id,
              condition: "IS_EQUALS_TO",
              value: this.$store.state.session.email,
            },
          ],
          groupCondition: "OR",
        });
      }
      let taskAssignField = formFields.find(
        (field) => field.label === "Task Assign"
      );
      if (taskAssignField) {
        taskFilterBy.push({
          filters: [
            {
              criteria: taskAssignField.id,
              condition: "IS_EQUALS_TO",
              value: this.$store.state.session.email,
            },
          ],
          groupCondition: "OR",
        });
      }
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getTaskEntries(
        this.selectedProcess.id,
        this.selectedProcess.processId,
        {
          filterBy: [
            {
              filters: [
                {
                  criteria: "createdBy",
                  condition: "IS_EQUALS_TO",
                  value: this.$store.state.session.id,
                },
              ],
            },
            ...taskFilterBy,
          ],
        }
      );
      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.taskList = payload;
        payload.forEach((row) => {
          let taskName = "",
            taskAssign = "",
            startDate = "",
            endDate = "",
            priority = "",
            status = "";
          formFields.forEach((field) => {
            switch (field.label) {
              case "Task Name":
                taskName = row.entryInfo[0][field.id];
                break;
              case "Task Assign":
                taskAssign = row.entryInfo[0][field.id];
                break;
              case "Start Date":
                startDate = row.entryInfo[0][field.id];
                break;
              case "End Date":
                endDate = row.entryInfo[0][field.id];
                break;
              case "Priority":
                priority = row.entryInfo[0][field.id];
                break;
              case "Task Status":
                status = row.entryInfo[0][field.id];
                break;
            }
          });
          this.taskEntries.push({
            id: row.entryInfo[0].itemid,
            name: taskName,
            assigned: taskAssign,
            startDate: startDate,
            endDate: endDate,
            priority: priority,
            status: status,
          });
        });
      }
    },

    async getTaskFormEntry(formId, entryId) {
      // this.$store.commit("showLoadingBarPage");

      const { error, payload } = await form.getFormEntry(formId, entryId);
      // this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        const panels = [
          ...this.addTaskForm.panels,
          ...this.addTaskForm.secondaryPanels,
        ];

        let formFields = [];
        for (const panel of panels) {
          formFields.push(...panel.fields);
        }
        let taskName = "",
          taskAssign = "",
          startDate = "",
          endDate = "",
          priority = "",
          status = "";
        formFields.forEach((field) => {
          switch (field.label) {
            case "Task Name":
              taskName = payload[0][field.id];
              break;
            case "Task Assign":
              taskAssign = payload[0][field.id];
              break;
            case "Start Date":
              startDate = payload[0][field.id];
              break;
            case "End Date":
              endDate = payload[0][field.id];
              break;
            case "Priority":
              priority = payload[0][field.id];
              break;
            case "Task Status":
              status = payload[0][field.id];
              break;
          }
        });
        this.taskEntries.push({
          id: payload[0].itemid,
          name: taskName,
          assigned: taskAssign,
          startDate: startDate,
          endDate: endDate,
          priority: priority,
          status: status,
        });
      }
    },

    async insertTaskEntry(formId, entryId) {
      // this.$store.commit("showLoadingBarPage");
      const { error } = await workflow.insertTaskEntry({
        workflowId: this.selectedWorkflow.id,
        processId: this.selectedProcess.processId,
        transactionId: this.selectedProcess.transactionId,
        wFormId: formId,
        formEntryId: entryId,
      });
      // this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.getTaskList();
    },

    validateType(key) {
      let fieldType = "";
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        let formField = panel.fields.find((field) => field.id === key);
        if (formField) {
          fieldType = formField.type;
        }
      });
      return fieldType;
    },

    validateValue(value) {
      let urlPattern =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return !urlPattern.test(value);
    },

    async showHistoryStepper(processId, wId) {
      console.log("called");
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.processHistory(wId, processId);
      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload.length) {
        this.stageLevel = [];
        this.currentStep = 0;
        // console.log(payload);
        let activityId = "";
        payload.forEach((row) => {
          if (row.actionStatus !== 2 && activityId !== row.activityId) {
            activityId = row.activityId;
            this.stageLevel.push({
              id: row.activityId,
              label: row.stage,
              status: row.status,
            });
            if (
              ((row.actionUser || row.subWorkflowHistory) && row.status) ||
              (row.actionUser === null && !row.subWorkflowHistory)
            ) {
              this.currentStep += 1;
            }
          }
          // if (row.actionStatus !== 2) {
          //   if (
          //     this.stageLevel.filter((stage) => stage.id === row.activityId)
          //       .length === 0
          //   ) {
          //     this.stageLevel.push({
          //       id: row.activityId,
          //       label: row.stage,
          //       status: row.status,
          //     });
          //     if (
          //       ((row.actionUser || row.subWorkflowHistory) &&
          //         row.status &&
          //         row.status !== "Forward") ||
          //       (row.actionUser === null && !row.subWorkflowHistory)
          //     ) {
          //       this.currentStep += 1;
          //     }
          //   } else if (row.status) {
          //     if (
          //       this.stageLevel.filter(
          //         (stage) =>
          //           stage.id === row.activityId && stage.status !== row.status
          //       ).length
          //     ) {
          //       this.currentStep += 1;
          //     } else {
          //       this.currentStep -= 1;
          //     }
          //   } else {
          //     let index = this.stageLevel.findIndex(
          //       (stage) => stage.id === row.activityId
          //     );
          //     if (index > -1) {
          //       this.stageLevel.splice(index, this.stageLevel.length - index);
          //       this.stageLevel.push({
          //         id: row.activityId,
          //         label: row.stage,
          //         status: row.status,
          //       });
          //       if (
          //         (row.actionUser && row.status && row.status !== "Forward") ||
          //         row.actionUser === null
          //       ) {
          //         this.currentStep = index + 1;
          //       } else {
          //         this.currentStep = index;
          //       }
          //     }
          //   }
          // }
        });
      }
    },

    initiateSubWorkflow() {
      this.initiateSubWorkflowProcess = true;
      //this.closeProcessSheet();
    },

    async getSubWorkflow() {
      this.tabs.find((tab) => {
        if (tab.value === "SUB_PROCESS") {
          tab.count = 0;
        }
      });

      const { error, payload } = await workflow.getSubWorkflow(
        this.workflow.value,
        this.selectedProcess.processId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      console.log(payload, "payload");

      if (payload.length) {
        this.getSubProcessDetails(payload[0].subWorkflowId, payload);
      }
    },

    async getSubProcessDetails(wId, data) {
      this.subProcessTickets = [];
      let filterBy = [];
      data.forEach((row) => {
        filterBy.push({
          filters: [
            {
              criteria: "processId",
              condition: "IS_EQUALS_TO",
              value: row.subProcessId,
              dataType: "SHORT_TEXT",
            },
          ],
          groupCondition: "or",
        });
      });
      if (filterBy.length) {
        filterBy[0].groupCondition = "";
      }
      const { error, payload } = await report.getWorkflowData(wId, {
        filterBy: filterBy,
        itemsPerPage: 0,
        currentPage: 0,
        mode: "",
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload);
      if (payload.meta.totalItems) {
        console.log(payload);
        let process = [];
        data.forEach((sub) => {
          let hasProcess = payload.data[0].value.find(
            (item) => item.processId === Number(sub.subProcessId)
          );
          console.log(hasProcess);
          if (hasProcess) {
            process.push(hasProcess);
          }
        });

        if (process.length) {
          this.subProcessTickets = process;
        }
        this.tabs.find((tab) => {
          if (tab.value === "SUB_PROCESS") {
            tab.count = process.length;
          }
        });
      }
    },

    subProcessShowLinks(value, process) {
      this.subProcessLink = "";
      this.subProcessData = process;
      this.subProcessLink = value;
    },

    openSubProcess(process) {
      this.subProcessData = process;
      this.openSubProcessSheet = true;
    },

    closeSubProcess() {
      this.openSubProcessSheet = false;
      this.subProcessData = {};
      this.initiateSubWorkflowProcess = false;
      // this.subProcessTickets = [];
    },

    async addSubWorkflowProcess(mainProcessId) {
      let input = {
        mainWorkflowId: this.workflow.value,
        subWorkflowId: this.subWorkflowId,
        mainProcessId: mainProcessId,
        subProcessId: this.subProcessId,
        subProcessStatus: 0,
        review: "",
      };
      const { error } = await workflow.addSubWorkflow(input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.updateSubWorkflowProcess();
    },

    async updateSubWorkflowProcess() {
      const { error, payload } = await transaction.updateSubWorkflowProcess(
        this.workflow.value,
        this.$store.state.session.tenantId,
        this.subWorkflowId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload);
    },

    closeSubProcessSheet() {
      this.subProcessSheet = false;
    },

    attachmentCount(count) {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = count;
        }
      });
    },

    commentsCount(count) {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = count;
        }
      });
    },

    async saveFilter(type) {
      this.success = false;
      let filter = JSON.stringify(this.sankeyChartsFilter);
      console.log(this.sankeyChartsFilter, "label");
      console.log(this.formFields, "label");
      let labelvalue = this.formFields.filter(
        (item) => this.sankeyChartsFilter.indexOf(item.value) > -1
      );
      console.log(labelvalue, "label");
      let filtering = [
        {
          uId: this.workflowId,
          type: type,
          filters: filter,
          label: labelvalue,
          formField: this.formFields,
        },
      ];
      let filterss = JSON.stringify(filtering);
      let userId = [this.$store.state.session.id];
      let name = "";
      let description = "";
      let now = new Date();
      let timestamp = now.toISOString();
      if (this.workflowId !== 0) {
        if (type === "sankey") {
          name = this.repositoryName + "  " + "sankey" + " " + timestamp;
          description = this.repositoryName;
        }
      }
      //   } else if (type === "bar") {
      //     name = this.repositoryName + "  " + item.key;
      //     description = item.key;
      //   } else if (type === "report") {
      //     name = this.repositoryName + "  " + item.workflowStatus;
      //     description = item.workflowStatus;
      //   } else if (type === "fullchart") {
      //     name = this.repositoryName + "  " + item.name;
      //     description = item.name;
      //   }
      // } else if (this.workflowId === 0) {
      //   name = "All" + "  " + item.workflowStatus;
      //   description = item.workflowStatus;
      // }
      let input = {
        name: name,
        description: description,
        filterJson: filterss,
        filterFrom: "workflowOverview",
        pinDashboard: true,
        assignTo: JSON.stringify(userId),
      };
      console.log(input, "input");
      const { error } = await dashboard.saveDashboard(input);
      if (error) {
        this.$alert.warning("The Name Already Registered ");
        return;
      }
      this.pined = true;
      this.getFilterList();
      this.$alert.success(name + "  " + "Pinned SucessFully");
    },
    async getFilterList() {
      this.loadingBarContent = true;
      let userId = this.$store.state.session.id.toString();
      const { error, payload } = await dashboard.getDashboardData({
        sortBy: this.sortBy,
        groupBy: "",
        filterBy: [
          {
            groupCondition: "",
            filters: [
              {
                criteria: "filterFrom",
                condition: "IS_EQUALS_TO",
                value: "workflowOverview",
              },
              {
                criteria: "createdBy",
                condition: "IS_EQUALS_TO",
                value: userId,
              },
              {
                criteria: "filterJson",
                condition: "CONTAINS",
                value: '"type":"sankey",',
              },
            ],
          },
        ],
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      });
      this.loadingBarContent = false;
      this.$store.commit("hideLoadingBarPage");
      if (error) {
        this.$alert.error(error);
        return;
      }
      let data = payload.data[0].value;
      this.listedData = data;
      console.log(this.listedData, "hytur");
    },

    deleteItem(type) {
      let names = "";
      if (this.workflowId !== 0) {
        if (type === "sankey") {
          names = this.repositoryName + "  " + "sankey" + " ";
        }
        this.deleteName = names;
        this.listedData.map((item) => {
          let parse = JSON.parse(item.filterJson);
          let filterParse = parse[0].filters.toString();
          let stringFilter = JSON.stringify([...this.sankeyChartsFilter]);
          let id = parse[0].uId;
          if (id === this.workflowId && stringFilter === filterParse) {
            this.isDeleteConfirmationVisible = true;
            this.deleteData = {
              id: item.id,
              name: item.name,
              description: item.description,
              filterJson: item.filterJson,
              filterFrom: item.filterFrom,
              createdAt: item.createdAt,
              modifiedAt: item.modifiedAt,
              createdBy: item.createdBy,
              modifiedBy: item.modifiedBy,
              isDeleted: true,
            };
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#workflow-overview {
  &.wrapper {
    position: relative;
    height: calc(100vh - 248px);
  }

  .description {
    color: var(--icon-color-inverted);
    margin-bottom: 4px;
  }

  .workflowCharts,
  .workflowReports {
    // height: calc(100vh - 235px);
    border: 1px solid #fff;
    padding: 5px;
    border-radius: 10px;
    background: white;
    width: 100%;
    .chart {
      height: 450px !important;
    }

    .border-bottom {
      border-bottom: 1px solid var(--divider-color);
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }
  }
}

#sheet {
  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .leftBorder {
    border-left: 1px solid var(--divider-color);
  }

  #action-btn.tabButtons {
    height: 52px;
    width: 52px;
  }
}

#sheet .attachmentList,
#sheet .taskEntries,
#sheet .checkList {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }

  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet .fileFrame {
  min-height: calc(100vh - 120px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet {
  #approvalSignature {
    background-color: var(--component-bg-color);
    border-radius: 4px;

    .title {
      @extend .text-md;
      font-weight: 600;
      text-transform: capitalize;
    }

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
    .text {
      text-align: center;
    }

    .imgCenter {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      height: 100px;
    }
  }
}

#modal {
  #action-btn.copyActions {
    width: 24px !important;
    height: 24px !important;
  }
}

#modal #processHistory {
  #history {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }

    .comments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    .attachments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    #comments1 {
      width: 100%;
      border-collapse: collapse;
      white-space: nowrap;
      // margin-bottom: 15px;
      background: #fcfafa;
      table-layout: fixed;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;

          &.small {
            width: 70px;
          }

          &.medium {
            width: 90px;
          }

          &.large {
            width: 210px;
          }
        }
      }

      th {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;

        &.commentsText {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.commentsText:hover {
          overflow: visible;
          white-space: normal;
          word-break: break-all;
        }
      }
    }

    #attachments {
      width: 98%;
      border-collapse: collapse;
      white-space: nowrap;
      margin-bottom: 5px;
      background: #fcfafa;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;
        }
      }

      th,
      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      .comments {
        color: var(--secondary);
        text-align: left;
        cursor: pointer;
      }
    }
  }
}

#sheet .hint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
  border: 2px dotted;
  border-radius: 10px;
  background-color: #e0ebfa26;
  padding: 5px;

  .icon {
    color: var(--body-text-color-inverted);
  }

  .title {
    @extend .title-2;
    font-weight: bold;
    margin-bottom: 2px;
  }

  .description {
    color: var(--icon-color);
    text-align: center;
  }
}

#sheet {
  #action-btn.commentAction {
    height: 32px !important;
    width: 32px !important;
  }

  #action-btn.comment-actions {
    height: 28px !important;
    width: 28px !important;
  }
}

.loading-content {
  background-color: var(--body-bg-color) !important;
  position: absolute;
  z-index: 9999;
}

#sheet .subProcess {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }

  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }

  .text-underline {
    text-decoration: underline;
  }
}
</style>

<style lang="scss">
#single-choice-field.actions .options-wrapper {
  padding-top: 8px !important;
}
#single-choice-field.actions.noborder .options-wrapper {
  border-top: 0px !important;
  padding-top: 0px !important;
}
.advance-cmd {
  margin-right: auto;
}
.file-icon {
  width: 18px !important;
  height: 18px !important;
}

.text-area-action {
  border: 1px solid var(--border-color);
  #field-wrapper {
    border: none !important;

    .is-focused {
      border: none;
    }
  }
}

.attach-cmd {
  #field-wrapper {
    height: 30px !important;
    .select {
      .value {
        width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .value:hover {
        max-width: none !important;
        overflow: visible;
        white-space: pre;
        position: fixed;
      }
    }
  }
}

.select-action {
  padding-top: 10px;
  align-items: center;
}

.action-container {
  border-top: 1px solid #cfd5dc;
}

.internal-public {
  display: flex;
  flex-direction: column-reverse;
  border-top: 1px solid #cfd5dc;
  padding-top: 10px;
}

.internal-public-margin {
  margin-top: 5px;
}
.comment-attach {
  #field-wrapper {
    height: 30px !important;
  }
}

#sheet {
  #form-view {
    background-color: var(--body-bg-color);
    margin: auto;
    padding: 16px;
  }
}

#requestDetails {
  table {
    &.submittedData {
      background: $primary-1;
    }
    &.mainData {
      table-layout: fixed;
    }
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--divider-color);

    tr {
      height: 36px;

      th {
        color: $primary-11;
        text-align: left;
      }
    }

    th,
    td {
      padding: 8px;
      font-weight: 500;
    }

    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid var(--divider-color);
    }

    tr:not(:last-child) {
      td,
      th {
        border-bottom: 1px solid var(--divider-color);
      }
    }

    .tblHeader {
      color: $primary-11;
      font-weight: 500;
      &.small {
        width: 200px;
      }
    }
  }

  .ellipsis:hover {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .text-underline {
    text-decoration: underline;
  }
}
</style>
